//Opacity 0 -> 1
@keyframes OPC-zero-to-one {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

//Opacity 1 -> 0
@keyframes OPC-one-to-zero {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

//Loop opacity
@keyframes OPC-loop {
	0% {
		opacity: 1;
	}
	50% {
		opacity: .2;
	}
	100% {
		opacity: 0;
	}
}
