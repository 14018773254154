@import "../includes";
@mixin material-icon() {
    font-family: 'Material Icons';
    text-rendering: optimizeLegibility;
    font-feature-settings: "liga" 1;
    font-style: normal;
    text-transform: none;
    line-height: 1;
    font-size: 24px;
    width: 24px;
    height: 24px;
    line-height: 1;
    display: inline-block;
    overflow: hidden;
    -webkit-font-smoothing: antialiased;
}

// Common
body {
    font-family: 'Roboto', -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

button,
input,
optgroup,
select,
textarea {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    &:focus,
    &:active,
    &:hover {
        outline: none !important;
        box-shadow: none !important;
    }
}

// Override bootstrap
@keyframes C-DDL-SHOW {
    0% {
        transform: scaleY(0);
    }
    100% {
        transform: scaleY(1);
    }
}

.dropdown-menu {
    border: none;
    border-radius: 2px;
    box-shadow: bottom-shadow(3);
    i {
        vertical-align: middle;
        transform: translateY(-1px);
        margin-right: 5px;
    }
    &.no-radius-top {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .dropdown-item.static-item:active,
    .dropdown-item.static-item:hover {
        background-color: #fff;
        color: #000;
    }
}

a:not([href]):not([tabindex]) {
    &.dropdown-item {
        cursor: pointer;
    }
    &.dropdown-item.active,
    &.dropdown-item:active {
        color: #fff;
    }
}

.dropdown {
    .dropdown-menu {
        transform: scaleY(0);
        transform-origin: 50% 0;
        .dropdown-item {
            opacity: 0;
            visibility: hidden;
        }
    }
    &.show {
        .dropdown-menu {
            animation: C-DDL-SHOW 250ms $EASE-OUT .05s forwards;
            .dropdown-item {
                visibility: visible;
                animation: OPC-zero-to-one 200ms $EASE-OUT .1s forwards;
            }
        }
    }
}

.btn {
    border: none;
    box-sizing: border-box;
    display: inline-block;
    font-family: Roboto, sans-serif;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    cursor: pointer;
    text-decoration: none;
    margin: 0px;
    padding: 0px;
    outline: none;
    font-size: inherit;
    font-weight: inherit;
    position: relative;
    z-index: 1;
    height: 36px;
    width: auto;
    line-height: 36px;
    border-radius: 2px;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    background-color: rgb(255, 255, 255);
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px;
    &:focus,
    &:hover {
        background-color: rgba(0, 0, 0, 0.0784);
        box-shadow: rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px !important;
        border-color: transparent;
    }
    span:not(.ink) {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0;
        text-transform: uppercase;
        &:only-child {
            margin: 0 12px;
        }
    }
    &:not(.no-shadow):focus {
        box-shadow: rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px !important;
    }
    i {
        position: relative;
        font-size: 24px;
        display: inline-block;
        user-select: none;
        transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
        vertical-align: middle;
        margin-left: 12px;
        margin-right: 0px;
        transform: translateY(-1px);
        &+span {
            margin-left: 6px;
            margin-right: 12px;
        }
        &.only-icon {
            margin: 0 7px;
        }
    }
    &:disabled {
        background-color: rgb(229, 229, 229) !important;
        cursor: default;
        box-shadow: none !important;
        i,
        span {
            color: rgba(0, 0, 0, 0.298039) !important;
        }
    }
    &.no-shadow {
        box-shadow: none;
    }
    &.btn-primary {
        background-color: #1976D2;
        color: #fff;
        &:hover {
            background-color: #64B5F6;
        }
    }
    &.btn-danger {
        background-color: rgb(255, 64, 129);
        color: #fff;
        &:hover {
            background-color: #EF9A9A;
        }
    }
    &.btn-info {
        background-color: #5bc0de;
        color: #fff;

        &:hover {
            background-color: #31b0d5;
        }
    }
    &.btn-success {
        background-color: #5cb85c;
        color: #fff;

        &:hover {
            background-color: #449d44;
        }
    }
    &.btn-warning {
        background-color: #f0ad4e;
        color: #fff;

        &:hover {
            background-color: #ec971f;
        }
    }

    &.btn-sm {
        @include clearfix();
        i {
            transform: translateY(2px);
        }
        i, span {
            float: left;
        }
    }
}

$TOOLTIP-DANGER: #F44336;
.tooltip {
    &.custom-tooltip-danger {
        .tooltip-inner {
            background-color: $TOOLTIP-DANGER;
            color: #fff;
        }
        &.tooltip-top .tooltip-inner:before,
        &.bs-tether-abutted-top .tooltip-inner:before,
        &.bs-tether-element-attached-bottom .tooltip-inner:before {
            border-top-color: $TOOLTIP-DANGER;
        }
        &.bs-tether-abutted-right .tooltip-inner:before,
        &.bs-tether-element-attached-left .tooltip-inner:before {
            border-right-color: $TOOLTIP-DANGER;
        }
        &.bs-tether-abutted-bottom .tooltip-inner:before,
        &.bs-tether-element-attached-top .tooltip-inner:before {
            border-bottom-color: $TOOLTIP-DANGER;
        }
        &.bs-tether-abutted-left .tooltip-inner:before,
        &.bs-tether-element-attached-right .tooltip-inner:before {
            border-left-color: $TOOLTIP-DANGER;
        }
    }


}

//Card
.card {
    color: rgba(0, 0, 0, 0.870588);
    background-color: rgb(255, 255, 255);
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    box-shadow: rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px;
    border-radius: 2px;
}

//Input & Datatable
@mixin dt-page-link-prop() {
    height: 36px;
    line-height: 36px;
}

input[type="file"].form-control {
    border: none;
}

input:not([type="file"]):not([type="checkbox"]):not([type="radio"]).form-control,
select.form-control,
textarea.form-control {
    border: none;
    border-bottom: solid 1px rgb(224, 224, 224);
    border-radius: 0;
    resize: none;

    &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: rgb(224, 224, 224);
        font-weight: 400;
    }
    &::-moz-placeholder {
        /* Firefox 19+ */
        color: rgb(224, 224, 224);
        font-weight: 400;
    }
    &:-ms-input-placeholder {
        /* IE 10+ */
        color: rgb(224, 224, 224);
        font-weight: 400;
    }
    &:-moz-placeholder {
        /* Firefox 18- */
        color: rgb(224, 224, 224);
        font-weight: 400;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        box-shadow: 0 0 0px 1000px white inset;
    }
    &:not(:disabled):focus {
        border-bottom-color: #5cb3fd;
    }
    &:disabled,
    &[readonly] {
        background-color: transparent;
        color: rgba(0, 0, 0, 0.298039);
        &::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: rgba(0, 0, 0, 0.298039);
        }
        &::-moz-placeholder {
            /* Firefox 19+ */
            color: rgba(0, 0, 0, 0.298039);
        }
        &:-ms-input-placeholder {
            /* IE 10+ */
            color: rgba(0, 0, 0, 0.298039);
        }
        &:-moz-placeholder {
            /* Firefox 18- */
            color: rgba(0, 0, 0, 0.298039);
        }
    }

    &.scripts-style {
        font-family: 'Consolas', 'Courier', 'INCONSOLATA', Arial !important;
    }
}

.form-group {
    &.has-danger {
        input.form-control,
        select.form-control {
            border-color: #F44336;
            &::-webkit-input-placeholder {
                /* Chrome/Opera/Safari */
                color: #EF9A9A;
            }
            &::-moz-placeholder {
                /* Firefox 19+ */
                color: #EF9A9A;
            }
            &:-ms-input-placeholder {
                /* IE 10+ */
                color: #EF9A9A;
            }
            &:-moz-placeholder {
                /* Firefox 18- */
                color: #EF9A9A;
            }
        }
        label:first-child {
            color: #F44336;
        }
    }
}

.form-inline {
	.form-group label {
		margin-bottom: 0;
		font-size: 1rem;
	}
}

.empty-table-message {
    font-size: 2rem;
    color: rgba(0,0,0,0.3);
    font-weight: 500;
}

.table,
.table.dataTable {
    border-collapse: collapse !important;
    th,
    td {
        font-size: 1rem;
        padding: 10px 15px;
    }
    thead th {
        font-weight: 500;
        border: none;
        border-bottom: 1px solid rgb(224, 224, 224);
    }
    tbody tr {
        &:hover {
            td {
                background-color: rgba(0,0,0,.05);
            }
        }
    }
    tbody tr td {
        border: none;
        border-bottom: 1px solid rgb(224, 224, 224);
    }
    .table-item-link {
        cursor: pointer;
        color: #2196F3;
        &:hover {
            color: #3D5AFE;
        }
    }
}

.dataTables_length,
.dataTables_filter,
.dataTables_info {
    font-size: 14px;
}

.dataTables_length label {
    justify-content: flex-start;
}

.dataTables_filter label {
    justify-content: flex-end;
}

.list-page-toolbar {
    padding-bottom: 10px;
    border-bottom: solid 1px #E0E0E0;
}

.dataTables_wrapper {
    *:focus {
        outline: none;
    }
    .filter-bar,
    .table-wrapper,
    .info-bar {
        padding-top: 10px;
        padding-bottom: 10px;
        background-color: #fff;
        border-radius: 2px;
        box-shadow: bottom-shadow(1);
    }
    .table-wrapper,
    .info-bar {
        margin-top: 15px;
    }
    .filter-bar label {
        display: inline-block !important;
    }
    .dataTables_filter {
        transform: translateY(3px);
    }
    .dataTables_paginate .pagination {
        float: right;
    }
    .dataTable thead th {
        position: relative;
    }
    .dataTable thead {
        .sorting,
        .sorting_asc,
        .sorting_desc,
        .sorting_asc_disabled,
        .sorting_desc_disabled {
            &:before,
            &:after {
                @include material-icon();
                width: 18px;
                height: 8px;
                line-height: 8px;
                color: #BDBDBD;
            }
            &:before {
                content: 'keyboard_arrow_up';
                bottom: 26px;
                right: 10px;
            }
            &:after {
                content: 'keyboard_arrow_down';
                bottom: 14px;
                right: 10px;
            }
            &.sorting_asc {
                &:before {
                    color: #304FFE;
                }
            }
            &.sorting_desc {
                &:after {
                    color: #304FFE;
                }
            }
        }
    }
    .pagination {
        .page-link {
            border: none;
            font-size: 14px;
            float: left;
            padding: 0 10px;
            @include dt-page-link-prop();
        }
        .previous,
        .next {
            .page-link {
                @include material-icon();
                @include dt-page-link-prop();
                padding: 0;
                width: 36px;
                text-align: center;
                border-radius: 50%;
            }
            &.disabled {
                color: #BDBDBD;
            }
        }
        .previous .page-link {
            margin-right: 10px;
        }
        .next .page-link {
            margin-left: 10px;
        }
    }
    .dataTables_length,
    .dataTables_filter {
        input,
        select {
            font-size: 14px;
        }
    }
}

.table-icon-wrapper {
    .active-icon {
        color: #388E3C;
    }
    .inactive-icon {
        color: #EF5350;
    }
}
.table-item-icon {
    &[role="using-global-item"] {
        i {
            color: #64B5F6;
        }
        &:hover {
            i {
                color: #2979FF;
            }
        }
    }
    &[role="edit"] {
        i {
            color: #4CAF50;
        }
        &:hover {
            i {
                color: #2E7D32;
            }
        }
    }
    &[role="delete"] {
        i {
            color: #BDBDBD;
        }
        &:hover {
            i {
                color: #E53935;
            }
        }
    }
    &[role="view"] {
        i {
            color: #64B5F6;
        }
        &:hover {
            i {
                color: #2979FF;
            }
        }
    }
  &[role="duplicate"] {
    i {
      color: #03a9f4;
    }
    &:hover {
      i {
        color: #0385c4;
      }
    }
  }
}
.table-item-label-wrapper {
    &:hover {
        color: rgba(0,0,0,.6);
    }
}

.material-bar {
    padding: 10px 16px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: bottom-shadow(1);
    max-width: 1200px;
    display: block;
    margin: 0 auto 15px;
}

//Tooltip
.tooltip-inner {
    text-align: left;
}

//Text editor
body .mce-ico {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga';
}

.mce-i-undo:before {
    content: 'undo' !important;
}

.mce-i-redo:before {
    content: 'redo' !important;
}

.mce-i-bold:before {
    content: 'format_bold' !important;
}

.mce-i-italic:before {
    content: 'format_italic' !important;
}

.mce-i-link:before {
    content: 'insert_link' !important;
}

.mce-i-image:before {
    content: 'photo' !important;
}

.mce-i-remove:before {
    content: 'clear' !important;
}

.mce-i-checkbox:before {
    content: 'check' !important;
}

.mce-i-underline:before {
    content: 'format_underlined' !important;
}

.mce-i-strikethrough:before {
    content: 'strikethrough_s' !important;
}

.mce-i-alignleft:before {
    content: 'format_align_left' !important;
}

.mce-i-aligncenter:before {
    content: 'format_align_center' !important;
}

.mce-i-alignright:before {
    content: 'format_align_right' !important;
}

.mce-i-alignjustify:before {
    content: 'format_align_justify' !important;
}

.mce-i-removeformat:before {
    content: 'format_clear' !important;
}

.mce-i-bullist:before {
    content: 'format_list_bulleted' !important;
}

.mce-i-numlist:before {
    content: 'format_list_numbered' !important;
}

.mce-i-preview:before {
    content: 'visibility' !important;
}

.mce-i-media:before {
    content: 'video_library' !important;
}

.mce-i-template:before {
    content: 'view_compact' !important;
}

.mce-i-code:before {
    content: 'code' !important;
}
.mce-i-browse:before {
    content: 'folder' !important;
}

.mce-i-forecolor:before {
  content: 'format_color_text' !important;
}

.mce-i-forecolor:before {
  content: 'format_color_text' !important;
}

.mce-i-backcolor:before {
  content: 'format_color_fill' !important;
}

.mce-i-cut:before {
  content: 'content_cut' !important;
}
.mce-i-copy:before {
  content: 'content_copy' !important;
}
.mce-i-paste:before {
  content: 'content_paste' !important;
}
.mce-i-forecolor, .mce-i-backcolor {
  background-color: transparent !important;
  + .mce-preview {
    display: none !important;
  }
}
.mce-colorbutton {
  position: relative;
  .mce-open {
    height: 36px;
    padding-left: 3px !important;
    padding-right: 3px !important;
    i {
      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(white,0);
      }
    }
  }
}
.mce-splitbtn {
  button {
    &:first-child {
      padding: 0 !important;
    }
    &.mce-open {
      padding-left: 3px !important;
      padding-right: 3px !important;
    }
  }
}
.mce-window {
    .mce-btn {
        height: 32px !important;
        border-radius: 2px;
        &:not(.mce-listbox) {
            width: 70px !important;
        }
        span {
            padding-left: 8px;
            padding-right: 8px;
        }
    }
    .mce-panel .mce-primary:hover {
        background-color: #448AFF;
    }
    .mce-combobox {
        .mce-btn:not(.mce-listbox) {
            box-sizing: border-box;
            border: none;
            border-radius: 0;
            background-color: transparent;
            color: rgba(0,0,0,0.4);

            &:hover {
                color: rgba(0,0,0,0.8);
            }
        }
        .mce-btn:not(.mce-listbox),
        .mce-btn:not(.mce-listbox) button,
        .mce-btn:not(.mce-listbox) button i:not(.mce-caret) {
            width: 30px !important;
            height: 30px !important;
        }
        .mce-btn:not(.mce-listbox) button i:not(.mce-caret) {
            line-height: 30px;
            text-align: center;
        }
    }
}

.mce-panel {
    .mce-flow-layout-item {
        margin: 0 3px;
    }
    .mce-btn {
        border: none;
        @include clearfix();
        &:not(.mce-disabled):hover {
            cursor: pointer;
            border: none;
            background-color: #448AFF;
            color: #fff;
            i,
            .mce-txt {
                color: #fff;
            }
        }
        &.mce-disabled:hover {
            background-color: transparent;
        }
        i:not(.mce-caret) {
            float: left;
            text-shadow: none;
            width: 36px;
            height: 36px;
            line-height: 36px;
            text-align: center;
        }
    }
    .mce-btn button {
        padding: 0;
    }
}

.mce-window-head {
    .mce-close .mce-ico {
        width: 38px;
        height: 38px;
        float: left;
        text-align: center;
        line-height: 38px;
    }
}

.mce-container-body {
    .mce-panel {
        background-color: #F5F5F5;
        .mce-btn {
            background-color: #F5F5F5;
            transition: background-color 400ms $EASE-OUT;
        }
        .mce-flow-layout-item {
            margin: 0;
        }
        .mce-btn-group:not(:first-child) {
            margin-left: 0;
            padding-left: 0;
        }
        .mce-flow-layout-item.mce-last {
            margin-right: 0;
        }
    }
    .mce-edit-area {
        border-color: #E0E0E0;
    }
    .mce-toolbar-grp {
        padding: 0;
    }
}

.mce-toolbar {
    .mce-menubtn {
        button {
            height: 36px;
            padding: 0 8px;
        }
    }
    &+.mce-toolbar {
        border-top: 1px solid #d9d9d9;
    }
}
.mce-window {
    .mce-listbox {
        &.mce-btn button {
            margin-top: 7px;
        }
        &.mce-btn span {
            margin-right: 20px;
        }
    }
}

//Breadcrumb
$BREADCRUMB-HOVER: #536DFE;
.breadcrumb {
    background-color: #EEEEEE;
    border-radius: 0;
    padding: 0;
    margin-bottom: 0;
    &.expanded {
        margin-left: -15px;
        margin-right: -15px;
    }
    .breadcrumb-item {
        @include clearfix();
        position: relative;
        a,
        span {
            font-size: 14px;
            padding: 0 1rem;
            height: 36px;
            line-height: 36px;
            float: left;
        }
        a:hover {
            text-decoration: none;
        }
        &.link-nav {
            background-color: #2196F3;
            a {
                color: #fff;
            }
            &:after {
                content: '';
                position: absolute;
                display: block;
                top: 0;
                right: 0;
                height: 0;
                width: 0;
                border-top: solid 18px transparent;
                border-right: solid 8px transparent;
                border-bottom: solid 18px transparent;
                border-left: solid 8px #2196F3;
                transform: translateX(16px);
            }
        }
        &:first-child {
            z-index: 5;
            &.link-nav {
                background-color: #757575;
                &:after {
                    border-left-color: #757575;
                }
                &:hover {
                    background-color: $BREADCRUMB-HOVER;
                    &:after {
                        border-left-color: $BREADCRUMB-HOVER;
                    }
                }
            }
        }
        &:nth-child(2) {
            z-index: 4;
            &.link-nav {
                background-color: #9E9E9E;
                &:after {
                    border-left-color: #9E9E9E;
                }
                &:hover {
                    background-color: $BREADCRUMB-HOVER;
                    &:after {
                        border-left-color: $BREADCRUMB-HOVER;
                    }
                }
            }
        }
        &:nth-child(3) {
            z-index: 3;
            &.link-nav {
                background-color: #BDBDBD;
                &:after {
                    border-left-color: #BDBDBD;
                }
                &:hover {
                    background-color: $BREADCRUMB-HOVER;
                    &:after {
                        border-left-color: $BREADCRUMB-HOVER;
                    }
                }
            }
        }
        &.active span {
            font-weight: 500;
        }
    }
    .breadcrumb-item+.breadcrumb-item {
        padding-left: 10px;
        &:before {
            display: none;
        }
    }
}

// Site selector
.app-site-selector {
    .site-selector-button {
        background-color: transparent;
        border: none;
        font-size: 1.3rem;
        font-weight: 500;
        transition: background-color 400ms $EASE-OUT;
        color: #616161;
        i {
            vertical-align: middle;
            transform: translateY(-2px);
            transform-origin: 50% 50%;
            transition: all 300ms $EASE-OUT;
        }
        &:not(.no-select):hover {
            cursor: pointer;
            background-color: #F5F5F5;
            color: #424242;
        }
    }
    .dropdown-menu {
        border-radius: 0 0 2px 2px;
        margin-top: 0;
    }
    .dropdown.show {
        .site-selector-button i {
            transform: translateY(-2px) rotate(180deg);
        }
    }
    .dropdown-item:not(.active) {
        &:hover {
            background-color: #82B1FF;
            color: #fff;
        }
        &:active {
            background-color: #2979FF;
            color: #fff;
        }
    }
    .dropdown-item.active {
        background-color: #448AFF;
        color: #fff;
        i {
            float: right;
            vertical-align: middle;
            margin-right: 0;
            transform: translateX(10px);
        }
    }
}

// Custom Datalist
.datalist-toolbar {
    width: 100%;
    border-radius: 2px;
    background-color: #fff;
    padding: 10px 15px;
    box-shadow: bottom-shadow(1);
    margin-bottom: 15px;
}

.datalist-wrapper {
    width: 100%;
    overflow: hidden;
    border-radius: 2px;
    background-color: #fff;
    box-shadow: bottom-shadow(1);
    transition: all 400ms $EASE-OUT;
    .datalist-item {
        padding: 10px 15px;
    }
}

// Pill list
.pill-list {
    .item {
        background-color: #fff;
        border-radius: 2px;
        padding: 10px 15px;
        box-shadow: bottom-shadow(1);
        margin-right: 10px;
        margin-bottom: 10px;
    }
}

// Translation Import-export
.translation-import-export-wrapper {
    &.busy {
        opacity: .4;
    }
}

// Language List
.languages-list-wrapper, .language-detail-page {
    &.busy {
        opacity: .4;
    }
}
.language-list {
    .item {
        background-color: rgba(0, 0, 0, 0.05);
        box-shadow: none;
        transition: all 200ms $EASE-OUT;
        cursor: pointer;
        position: relative;

        &.editing {
            padding: 8px 15px;
            background-color: #448AFF;

            i {
                color: rgba(255,255,255,.3);

                &:hover {
                    color: rgba(255,255,255,1);
                }
            }
        }

        &.add-new-item {
			background-color: transparent;
			padding: 0;
			box-shadow: none;

            .language-dropdown > .btn {
                background-color: rgba(0, 0, 0, 0.05);
                height: 44px;
                box-shadow: none;
                color: rgba(0, 0, 0, 0.5);
				box-shadow: none;

                i {
                    float: left;
                    height: 24px;
                    width: 18px;
                    text-align: center;
                    line-height: 24px;
					transform: translateY(6px);
					margin-right: 6px;
                }

				&:after {
					display: none;
				}

				&:hover {
					background-color: $BREADCRUMB-HOVER;

					i, span {
						color: #fff;
					}
				}
            }
        }

        &:not(.add-new-item):not(.active):not(.editing):hover {
            background-color: #448AFF;

			.lang-code-label, i {
                color: #fff;
            }
        }

        &.active {
            background-color: #fff;
            box-shadow: bottom-shadow(1);

            .edit-language-box {
                i {
                    color: rgba(0, 0, 0, 0.3);

                    &:hover {
                        color: rgba(0, 0, 0, 1);
                    }
                }
            }
        }

        &.inactive {
            .flag-icon, .lang-code-label {
                opacity: .4;
            }
        }

        .order-number {
            position: absolute;
            width: 24px;
            height: 24px;
            top: 0;
            left: 50%;
            z-index: 3;
            transform: translate3d(-50%, -30px, 0);
            text-align: center;
            line-height: 22px;
            border-radius: 50%;
            border: solid 2px rgba(0,0,0,0.2);
            color: rgba(0,0,0,0.2);
            font-weight: 600;
            font-size: 14px;
        }

        &.ui-sortable-helper {
            box-shadow: bottom-shadow(3);
            width: auto !important;
            height: 44px !important;
            background-color: #fff;
            border-radius: 4px;
            border-bottom-color: transparent;
            transition: none;

            .lang-code-label, .order-number {
                display: none;
            }

            &:not(.add-new-item):not(.active):hover {
                background-color: #fff;
            }
        }

        .remove-item {
            width: 24px;
            height: 24px;
            font-size: 18px;
            line-height: 24px;
            text-align: center;
            border-radius: 50%;
            float: left;
            margin-left: 10px;
            color: rgba(0,0,0,0.5);

            &:hover {
                background-color: #EF5350;
                color: #fff;
            }
        }
    }
    .item-placeholder {
        position: relative;
        height: 44px;
        width: 100px;
        transition: background-color 400ms $EASE-OUT;
        border: dashed 2px #FFB74D;
        background-color: #FFF3E0;
        margin-right: 10px;
    }
}
.sidenav-lang-list {
	ul li .lang-item {
		background-color: #fff;
        border-radius: 2px;
        box-shadow: bottom-shadow(2);
        padding: 10px;

        i {
            width: 32px;
            text-align: center;
            height: 24px;
            line-height: 20px;
            color: #aaa;
        }

        &:not(.active):hover {
            background-color: #eee;
        }
        &.active {
            background-color: #448AFF;

            i {
                color: #fff;
            }
        }
	}
}

.list-message-wrapper {
    font-size: 1.5rem;
    color: rgba(0,0,0,0.5);

    ul {
        margin-top: 15px;
        margin-left: 15px;

        li {
            list-style-type: disc;
            font-size: 1rem;
        }
    }
}
