@import "../includes";

$CHKBOX-ANIM-TIME-OFFSET: 100ms;

@mixin default-switch-element() {
	position: relative;
	cursor: pointer;
	overflow: visible;
	display: table;
	height: auto;
	width: 100%;
	margin-bottom: 8px;

	.md-switch-element {
		position: absolute;
		cursor: pointer;
		pointer-events: all;
		opacity: 0;
		width: 100%;
		height: 100%;
		z-index: 2;
		left: 0px;
		box-sizing: border-box;
		padding: 0px;
		margin: 0px;
	}

	.effect-wrapper {
		@include pos(relative);
		@include zidx(1);
		display: flex;
		width: 100%;
		height: 100%;

		.box-style-wrapper {
			transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
			float: left;
			position: relative;
			display: block;
			flex-shrink: 0;
			width: 24px;
			margin-right: 10px;
			margin-left: 0px;
			height: 24px;

			.svg-wrapper {
				svg:first-child {
					display: inline-block;
					color: rgba(0, 0, 0, 0.870588);
					fill: rgba(0, 0, 0, 0.870588);
					height: 24px;
					width: 24px;
					transition: opacity (600ms - $CHKBOX-ANIM-TIME-OFFSET) cubic-bezier(0.23, 1, 0.32, 1) 200ms;
					position: absolute;
					opacity: 1;
					user-select: none;
				}
				svg:nth-child(2) {
					display: inline-block;
					color: rgba(0, 0, 0, 0.870588);
					fill: rgb(0, 188, 212);
					height: 24px;
					width: 24px;
					transition: opacity (450ms - $CHKBOX-ANIM-TIME-OFFSET) cubic-bezier(0.23, 1, 0.32, 1) 0ms, transform 0ms cubic-bezier(0.23, 1, 0.32, 1) 450ms;
					position: absolute;
					opacity: 0;
					transform: scale(0);
					user-select: none;
				}
			}
			.color-effect {
				& > span {
					height: 200%;
					width: 200%;
					position: absolute;
					top: -12px;
					left: -12px;
					overflow: hidden;
					color: rgba(0, 0, 0, 0.870588);
				}
			}
			.style-helper {
				position: absolute;
				overflow: hidden;
				height: 100%;
				width: 100%;
				top: 0px;
				left: 0px;
			}
		}

		.switch-element-label {
			float: left;
			position: relative;
			display: block;
			width: calc(100% - 60px);
			line-height: 24px;
			color: rgba(0, 0, 0, 0.870588);
			font-family: $COMMON-FONT;
			font-weight: $BASE-FNT-WEIGHT;
		}
	}

	.md-switch-element:checked {
		& + .effect-wrapper {
			.box-style-wrapper {
				.svg-wrapper {
					svg:first-child {
						@include opc(0);
						color: rgba(0, 0, 0, 0.870588);
						fill: rgb(0, 188, 212);
						transition: opacity (350ms - $CHKBOX-ANIM-TIME-OFFSET) cubic-bezier(0.23, 1, 0.32, 1) 0;
					}
					svg:nth-child(2) {
						@include opc(1);
						transition: opacity 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, transform (800ms - $CHKBOX-ANIM-TIME-OFFSET) cubic-bezier(0.23, 1, 0.32, 1) 0ms;
						transform: scale(1);
					}
				}

				.color-effect {
					& > span {
						color: rgb(0, 188, 212);
					}
				}
			}
		}
	}

	.md-switch-element:disabled {
		& + .effect-wrapper {
			.box-style-wrapper {
				.svg-wrapper {
					svg:first-child, svg:nth-child(2) {
						fill: rgba(0, 0, 0, 0.298039) !important;
					}
				}
			}
			.switch-element-label {
				color: rgba(0, 0, 0, 0.298039);
			}
		}
	}

	&.md-radio-wrapper {
		width: auto;
		margin-bottom: 0;

		.effect-wrapper {
			.box-style-wrapper {

				.svg-wrapper {
					svg:first-child {
						display: inline-block;
						color: rgba(0, 0, 0, 0.870588);
						fill: rgba(0, 0, 0, 0.870588);
						height: 24px;
						width: 24px;
						transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
						position: absolute;
						opacity: 1;
						transform: scale(1);
						user-select: none;
					}
					svg:nth-child(2) {
						display: inline-block;
						color: rgba(0, 0, 0, 0.870588);
						fill: rgb(0, 188, 212);
						height: 24px;
						width: 24px;
						transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
						position: absolute;
						opacity: 1;
						transform: scale(0);
						transform-origin: 50% 50% 0px;
						user-select: none;
					}
				}
			}
		}

		.md-switch-element:checked {
			& + .effect-wrapper {
				.box-style-wrapper {
					.svg-wrapper {
						svg:first-child {
							display: inline-block;
							color: rgba(0, 0, 0, 0.870588);
							fill: rgba(0, 0, 0, 0.870588);
							height: 24px;
							width: 24px;
							transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
							position: absolute;
							opacity: 0;
							transform: scale(0);
							user-select: none;
						}
						svg:nth-child(2) {
							display: inline-block;
							color: rgba(0, 0, 0, 0.870588);
							fill: rgb(0, 188, 212);
							height: 24px;
							width: 24px;
							transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
							position: absolute;
							opacity: 1;
							transform: scale(1);
							transform-origin: 50% 50% 0px;
							user-select: none;
						}
					}
				}
			}
		}
	}
}

.md-checkbox-wrapper, .md-radio-wrapper {
	@include default-switch-element();
}

.radio-group-wrapper {
	&.inline-radio-group {
		display: flex;
		flex-flow: row wrap;
		justify-content: flex-start;
		align-items: flex-start;

		.inline-radio-block {
			flex: 0 0 auto;
			align-self: flex-start;

			& + .inline-radio-block {
				margin-left: 24px;
			}
		}
	}
}