.vertical-grid-line,
.horizontal-grid-line {
  fill: none;
  shape-rendering: crispEdges;
  stroke: #EFF2F5;
  stroke-width: 1;
  stroke-dasharray: 4, 4; }

.extended-y-line,
.extended-x-line {
  fill: none;
  shape-rendering: crispEdges;
  stroke: #D2D6DF;
  stroke-width: 1; }

.tick line {
  fill: none;
  stroke: #ADB0B6;
  stroke-width: 1;
  shape-rendering: crispEdges; }

.tick text {
  font-size: 1rem;
  fill: #666A73;
  padding: 12px; }

.y-axis-label,
.x-axis-label {
  font-size: 1rem;
  fill: #ADB0B6; }

.vertical-marker-container .vertical-marker {
  stroke: #D2D6DF;
  stroke-width: 1;
  fill: none; }

.vertical-marker-container .data-point-highlighter {
  fill: #ffffff;
  stroke-width: 2; }

.tooltip-background {
  fill: rgba(255, 255, 255, 0.97);
  stroke: #D2D6DF;
  stroke-width: 1;
  border-radius: 2px; }

.britechart-legend .legend-entry.is-faded .legend-entry-name,
.britechart-legend .legend-entry.is-faded .legend-entry-value,
.britechart-legend .legend-entry.is-faded .legend-circle {
  opacity: 0.97;
  transition: opacity .2s ease-out;
  -moz-transition: opacity .2s ease-out;
  -webkit-transition: opacity .2s ease-out; }

.britechart-legend .legend-entry.is-faded .legend-entry-name,
.britechart-legend .legend-entry.is-faded .legend-entry-value,
.britechart-legend .legend-entry.is-faded .legend-circle {
  opacity: 0.2; }
