@import "../includes";

ul, ol {
	margin: 0;
	padding: 0;
	list-style: none;
}
a, .link-item {
	text-decoration: none;
	color: #2962FF;
	cursor: pointer;

	&:hover {
		text-decoration: none;
		color: #448AFF;
	}
}
.label-alignment {
	transform: translateY(-2px);
	margin-right: 2px;
}