@import "../_includes";

//Typeahead
.twitter-typeahead {
    display: block !important;
}
.tt-menu {
    background-color: #fff;
    box-shadow: bottom-shadow(2);
    border-radius: 3px;
    overflow: hidden;

    .tt-selectable {
        padding: 7px 15px;
        cursor: pointer;

        &:hover {
            background-color: #448AFF;
            color: #FFF;
        }

        &.tt-cursor {
            background-color: #448AFF;
            color: #FFF;
        }
    }

    .no-item-label {
        display: block;
        padding: 7px 15px;
        color: #BDBDBD;

        i {
            vertical-align: middle;
            transform: translateY(-2px);
            margin-right: 4px;
        }
    }
}