@import "../_includes";

@keyframes SHOW-EDIT-CONTENT {
	0% {
		transform: translateX(calc(100% + 15px));
		opacity: 0;
	}
	100% {
		transform: translateX(0);
		opacity: 1;
	}
}
.page-list-wrapper {
	display: flex;
	flex-flow: row wrap;

	&.hidden {
		display: none;
	}

	&.has-selected-page {
		.page-item:not(.selected) {
			display: none;
		}
	}

	.page-item {
		position: relative;
		flex: 0 0 100%;
		height: 250px;
		width: 100%;
		border-radius: 3px;
		// margin: 15px 0 15px 0;
		overflow: hidden;

		color: rgba(0, 0, 0, 0.870588);
		background-color: rgb(255, 255, 255);
		transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		box-shadow: bottom-shadow(1);
		border-radius: 2px;

		// NOTE: fixed ui
		height: auto;
		background-color: transparent;
		margin: 5px auto;

		&:hover, &:focus {
			color: white;
			.page-item-title {
				color: white;
				background-color: #2979FF;
			}
			.page-item-title {
				.template-name {
					color: white;
				}
			}
			.page-item-edit, .page-item-view-sub {
				background-color: white;
			}
		}

		.page-item-title {

			// position: absolute;
			// bottom: 0;
			// left: 0;
			// right: 0;
			padding: 15px;
			// background-color: rgba(0, 0, 0, 0.541176);
			color: #000;
			z-index: 3;
			@include clearfix();

			// NOTE: fixed ui
			position: relative;
			display: block;
			height: 100%;
			padding-right: 102px;
			background-color: white;

			label {
				display: block;
				font-size: 18px;
				float: left;
				margin-bottom: 0;
			}

			.template-name {
				display: block;
				width: 100%;
				font-size: 13px;
				margin-top: 0;
				color: rgba(255,255,255,.5);
				float: left;

				// NOTE:
				color: #666;
			}
		}

		.page-image-icon {
			position: absolute;
			z-index: 1;
			font-size: 80px;
			color: rgba(0, 0, 0, 0.12);
			top: 40%;
			left: 50%;
			transform: translate3d(-50%, -50%, 0);

			// NOTE: fixed ui
			display: none;
		}

		&:hover {
			cursor: pointer;
			box-shadow: bottom-shadow(3);

			.page-item-edit, .page-item-view-sub {
				display: block;
			}
		}
		&:focus, &:active {
			outline: none;
		}

		.page-item-action{
			display: flex;
			position: absolute;
			z-index: 4;
			right: 10px; top: 10px;
			a{ 
				display: block;
			}
			.material-icons {
				display: block;
				width: 36px;
				height: 36px;
				line-height: 36px;
				text-align: center;
				border-radius: 50%;
				transition: background-color 400ms $EASE-OUT, color 400ms $EASE-OUT;
				color: rgba(0,0,0,0.3);

				box-shadow: bottom-shadow(2);
				background: rgba(255,255,255,0.1);
				&:hover {

					background-color: #448AFF;
					color: #fff;
				}
			}
		}

		.page-item-edit, .page-item-view-sub {
			position: absolute;
			z-index: 4;
			width: 36px;
			height: 36px;
			line-height: 36px;
			text-align: center;
			border-radius: 50%;
			top: 10px;
			right: 10px;
			transition: all 400ms $EASE-OUT;
			// display: none;
			color: rgba(0,0,0,0.3);

			box-shadow: bottom-shadow(2);
			background: rgba(255,255,255,0.1);
			&:hover {

				background-color: #448AFF;
				color: #fff;
			}
		}

		.page-item-edit {
			font-size: 20px;
		}

		.page-item-view-sub {
			// top: 50px;
			line-height: 38px;
			font-size: 14px;

			// NOTE: fixed ui
			top: 10px;
			right: 96px;
			color: rgba(0,0,0,0.3);
			font-weight: bold;
		}
	}

	&.busy {
		opacity: .2;
	}
}
.page-detail-wrapper {
	position: relative;
	margin: 0 auto;
	max-width: 900px;
	transition: all 550ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
	padding: 30px;

	&:not(.no-style) {
		color: rgba(0, 0, 0, 0.870588);
		background-color: rgb(255, 255, 255);
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		box-shadow: rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px;
		border-radius: 3px;
	}

	.page-item-list-wrapper {
		.page-item {
			position: relative;
			min-height: 60px;
			padding: 8px 15px;
			transition: background-color 500ms $EASE-OUT;
			border: solid 2px transparent;
			display: flex;
			flex-flow: row nowrap;
			align-items: flex-start;
			justify-content: flex-start;

			&:not(:last-child) {
				border-bottom-color: rgba(0,0,0,.1);
			}

			&:hover {
				cursor: pointer;
				background-color: #f0f0f0;

				.title {
					color: #424242;
				}
			}
			&:focus {
				outline: none;
			}

			&.ui-sortable-helper {
				box-shadow: bottom-shadow(3);
				width: 200px !important;
				background-color: #fff;
				border-radius: 4px;
				border-bottom-color: transparent;

				.action-button-wrapper {
					display: none;
				}

				.title {
					flex: 0 0 100%;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
				}
			}

			.title {
				display: block;
				flex: 1 0 auto;
				color: #757575;
				font-size: 1.4rem;
				font-weight: 400;
				margin: 0;
				height: 40px;
				line-height: 40px;

				.page-item-using-global-item {
					transform: translateY(3px);
					padding-right: 5px;
					color: #64B5F6;
				}
			}
			.action-button-wrapper {
				flex: 0 1 60px;
			}
		}
		.page-item-placeholder {
			position: relative;
			height: 60px;
			transition: background-color 400ms $EASE-OUT;
			border: dashed 2px #FFB74D;
			background-color: #FFF3E0;

			&:before {
				content: "Drop here to reorder item";
				position: absolute;
				display: block;
				top: 15px;
				left: 15px;
				color: #FFB74D;
				font-size: 18px;
			}
		}
		.action-button-wrapper {
			.action-button {
				@include clearfix();
				float: left;

				i {
					text-align: center;
					float: left;
					width: 40px;
					height: 40px;
					line-height: 40px;
					border-radius: 50%;
					font-size: 24px;
					color: #BDBDBD;

					&:hover {
						background-color: #536DFE;
						color: #fff;
					}
				}
			}
		}
	}

	&.edit-mode {
		opacity: 0;
		transform: translateX(calc(-100% - 30px));
	}
}
.page-detail-edit-mode {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow: hidden;
	transform: translateX(calc(100% + 15px));
	opacity: 0;

	&.edit-mode {
		animation: SHOW-EDIT-CONTENT 550ms cubic-bezier(0.23, 1, 0.32, 1) forwards;
	}

	.inner-scroll-area {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 60px;
		padding: 30px;
		overflow: hidden;
		overflow-y: auto;
		transform: translateX(calc(100% + 15px));
		opacity: 0;

		&.ready {
			animation: SHOW-EDIT-CONTENT 550ms cubic-bezier(0.23, 1, 0.32, 1) 100ms forwards;
		}
	}

	&:not(.no-style) {
		.inner-wrapper {
			@include clearfix();

			position: relative;
			display: block;
			color: rgba(0, 0, 0, 0.870588);
			background-color: rgb(255, 255, 255);
			-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
			box-shadow: rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px;
			border-radius: 3px;
			padding: 15px;
		}
	}
}
.page-item-edit-mode {
	position: absolute;
	transition: all 550ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
	transform: translateX(calc(100% + 15px));
	top: 0;
	right: 0;
	bottom: 0;
	left: 60px;
	padding: 30px;
	overflow: hidden;
	overflow-y: auto;
	opacity: 0;


	&:not(.no-style) {
		.inner-wrapper {
			@include clearfix();

			position: relative;
			display: block;
			color: rgba(0, 0, 0, 0.870588);
			background-color: rgb(255, 255, 255);
			-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
			box-shadow: rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px;
			border-radius: 3px;
			padding: 15px;
		}
	}

	.content-wrapper {
		position: relative;
		height: 500px;
		margin: 30px auto;
		max-width: 600px;
		color: rgba(0, 0, 0, 0.870588);
		background-color: rgb(255, 255, 255);
		transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		box-shadow: rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px;
		border-radius: 3px;
		padding: 15px;
	}

	&.edit-mode {
		transform: translateX(0);
		opacity: 1;
	}

	.form-group:not(.no-mgn-bottom) {
		margin-bottom: 30px;
	}
}
.go-back-btn {
	position: absolute;
	z-index: 3;
	top: 30px;
	left: 15px;
	transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
	transform: translateY(-100px);
	opacity: 0;

	i {
		width: 48px;
		height: 48px;
		border-radius: 50%;
		text-align: center;
		line-height: 48px;
		font-size: 28px;
		box-shadow: bottom-shadow(2);
		background-color: #fff;

		&:hover {
			cursor: pointer;
			background-color: #536DFE;
			color: #fff;
		}
	}

	&.edit-mode {
		transform: translateY(0);
		opacity: 1;
	}
}
.image-item-preview {
	position: relative;

	&.changing {
		opacity: .3;
	}

	img {
		max-width: 100%;
		max-height: 180px;
	}
	.image-panel {
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		align-items: flex-start;

		span:first-child {
			max-width: 300px;
			flex: 0 0 auto;
			padding-right: 15px;
		}
		span:nth-child(2) {
			flex: 0 0 auto;
		}
	}
}
.broken-image {
	display: flex;
	flex-flow: column nowrap;
	align-content: stretch;
	justify-content: flex-start;
	height: 135px;
	width: 240px;
	background-color: rgba(0, 0, 0, .1);
	text-align: center;
	border-radius: 2px;

	i {
		flex: 1 0 auto;
		font-size: 70px;
		width: 240px;
		line-height: 135px;
		text-align: center;
		color: rgba(0, 0, 0, 0.2);
	}

	&.small {
		height: 60px;
		width: 100px;

		i {
			font-size: 30px;
			width: 100px;
			line-height: 60px;
		}
	}
}
.file-upload-changed-label {
	padding: 8px 0;
	font-size: 14px;

	i {
		vertical-align: middle;
		transform: translateY(-1px);
	}
}
.page-category-filter-box {
	left: auto;
	right: 40px;
	top: 200px;
}
.page-filter-box {
	position: fixed;
	margin-left: auto;
	margin-right: auto;
	//top: $HDR-H + 65px;
	z-index: 20;
	//left: $SIDENAV-W + 30px;
	background-color: #fff;
	border-radius: 2px;
	box-shadow: bottom-shadow(2);
	padding: 8px 15px;
	width: 250px;

	& + .page-list-wrapper {
		margin-top: 150px;
	}

	&.showing-child {
		display: flex;
		flex-flow: row nowrap;

		.back-to-parent {
			flex: 0 0 37px;
			transform: translateX(-5px);

			i {
				width: 37px;
				height: 37px;
				line-height: 37px;
				text-align: center;
				border-radius: 50%;

				&:hover {
					cursor: pointer;
					background-color: rgba(0,0,0,0.1);
				}
			}
		}
		.search-box {
			flex: 0 0 calc(100% - 37px);
		}
	}
}
.file-item-preview {
	display: flex;
	flex-flow: column nowrap;
	align-content: stretch;
	justify-content: flex-start;
	width: 320px;
	height: 180px;
	background-color: rgba(0, 0, 0, .4);
	text-align: center;
	border-radius: 2px;
	margin-bottom: 10px;
	background-color: #fff;
	border-radius: 2px;
	box-shadow: bottom-shadow(1);
	overflow: hidden;
	position: relative;

	i {
		flex: 1 0 auto;
		font-size: 70px;
		width: 320px;
		height: 180px;
		line-height: 150px;
		text-align: center;
		color: rgba(0, 0, 0, 0.4);
	}

	.filename {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 8px 15px;
		color: #fff;
		z-index: 10;
		background-color: rgba(0,0,0,0.6);
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

// Flex List
.flex-list {
	display: flex;
	flex-flow: row wrap;
	align-items: flex-start;
	justify-content: flex-start;

	.item {
		flex: 0 0 auto;
		@include clearfix();
	}
}

// Flag icon
.flag-icon {
	float: left;
	height: 24px;
	width: 32px;
	overflow: hidden;
	@include clearfix();

	img {
		float: left;
		transform: translateY(-4px);
	}
}
.lang-code-label {
	float: left;
	height: 24px;
	line-height: 24px;
	font-size: 16px;

	&:not(:only-child) {
		padding-left: 10px;
	}
}
.languages-list-wrapper {
	position: relative;
	transition: all 500ms $EASE-OUT;
	padding: 15px;
	z-index: 3;

	&.hiding {
		transform: translateX(-100%);
	}
}
.language-detail-page {
	position: relative;
	z-index: 2;
	opacity: 0;
	padding: 15px;
	transition: opacity 400ms $EASE-OUT;
	will-change: opacity;

	&.displayed {
		opacity: 1;
	}
}
.language-dropdown {
	.dropdown-menu {
		width: 260px;
	}
	.dropdown-item {
		@include clearfix();
		padding-left: 15px;
		padding-right: 15px;

		img {
			float: left;
			margin-right: 7px;
		}

		span {
			height: 32px;
			float: left;
			line-height: 32px;
		}
	}
}
.edit-language-box {
	@include clearfix();

	.form-control {
		width: 140px;
		float: left;
		height: 28px;
	}
	i {
		float: left;
		width: 24px;
		text-align: center;
		height: 28px;
		line-height: 28px;
		margin-left: 4px;
	}
}

//Sidenav language list
$SIDE-LANG-ITEM-W: 48px;
.sidenav-lang-list {
	position: absolute;
	z-index: 3;
	top: 100px;
	left: 0;
	transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
	transform: translateX(-68px);

	ul li {
		@include clearfix();

		& + li {
			margin-top: 15px;
		}

		.lang-item {
			display: block;
			float: left;
			height: 40px;
			overflow: hidden;

			img {
				transform: translateY(-6px);
			}
		}
	}

	&.edit-mode {
		transform: translateX(15px);
	}

	&.static {
		transform: translateX(0);
	}
}

//Page Preview
.preview-frame {
	width: 100%;
	height: 100%;
	min-height: 600px;
	border: solid 1px #ccc;
	float: left;
}
[cmp-page-preview] {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

@media screen and (max-width: 36em) {
	.page-filter-box {
		left: 50%;
		transform: translateX(-50%);
	}
}

@media screen and (min-width: 37em) and (orientation: landscape) {
	.page-category-filter-box {
		top: unset;
	}
	.page-filter-box + .page-list-wrapper {
		margin-top: 90px;
	}
}

@media screen and (min-width: 47em) {
	.page-category-filter-box {
		top: unset;
	}
	.page-filter-box + .page-list-wrapper {
		margin-top: 90px;
	}
}

@media screen and (min-width: 47em) and (orientation: portrait) {
	.page-list-wrapper {
		// .page-item {
		// 	flex: 0 0 calc(20% - 30px);
		// 	width: calc(20% - 30px);
		// 	margin: 15px;
		// }
	}
}

@media screen and (min-width: 64em) {
	.page-category-filter-box {
		top: unset;
	}
	.page-detail-wrapper, .content-wrapper,
	.page-item-edit-mode .content-wrapper {
		max-width: 800px;
	}
	.page-filter-box {
		width: 350px;
		margin-left: unset;
		margin-right: unset;

		& + .page-list-wrapper {
			margin-top: 90px;
		}
	}
	.page-list-wrapper {
		margin-left: -5px;
		margin-right: -5px;
		.page-item {
			// flex: 0 0 calc(20% - 30px);
			// width: calc(20% - 30px);
			// margin: 15px;

			// // NOTE: fixed ui
			flex: 0 0 calc(50% - 10px);
			margin: 5px;

		}
	}
}
.translation-import-export-wrapper {
	display: flex;
	justify-content: center;

	.translation-import-export-box {
		background-color: #fff;
		box-shadow: 0 1.5px 4px rgba(0,0,0,0.24);
		padding: 20px 0;
		margin: 20px;
		text-align: center;

		.action-box {
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 20px;
			cursor: pointer;

			i {
				font-size: 60px;
				color: #2979FF;
			}

			&:hover {
				i {
					color: #275cb8;
				}
			}
		}
	}
}

@media screen and (min-width: 73.125em) {
	.page-detail-wrapper, .content-wrapper,
	.page-item-edit-mode .content-wrapper {
		max-width: 1000px;
	}
}
@media screen and (min-width: 87.5em) {
	.page-detail-wrapper, .content-wrapper,
	.page-item-edit-mode .content-wrapper {
		max-width: 1200px;
	}
}
// @media screen and (min-width: 120em) {
// 	.page-detail-wrapper, .content-wrapper,
// 	.page-item-edit-mode .content-wrapper {
// 		max-width: 1200px;
// 	}
// }
