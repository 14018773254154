/* Animation */
@mixin anim($value: none) {
	-webkit-animation: $value;
	-o-animation: $value;
	animation: $value;
}
@mixin anim-delay($value: 0s) {
	-webkit-animation-delay: $value;
	-moz-animation-delay: $value;
	-o-animation-delay: $value;
	animation-delay: $value;
}
@mixin anim-direction($value: normal) {
	-webkit-animation-direction: $value;
	-moz-animation-direction: $value;
	-o-animation-direction: $value;
	animation-direction: $value;
}
@mixin anim-duration($value: 0s) {
	-webkit-animation-duration: $value;
	-moz-animation-duration: $value;
	-o-animation-duration: $value;
	animation-duration: $value;
}
@mixin anim-fill-mode($value: none) {
	-webkit-animation-fill-mode: $value;
	-moz-animation-fill-mode: $value;
	-o-animation-fill-mode: $value;
	animation-fill-mode: $value;
}
@mixin anim-iteration-count($value: 1) {
	-webkit-animation-iteration-count: $value;
	-moz-animation-iteration-count: $value;
	-o-animation-iteration-count: $value;
	animation-iteration-count: $value;
}
@mixin anim-name($value: none) {
	-webkit-animation-name: $value;
	-moz-animation-name: $value;
	-o-animation-name: $value;
	animation-name: $value;
}
@mixin anim-play-state($value: running) {
	-webkit-animation-play-state: $value;
	-moz-animation-play-state: $value;
	-o-animation-play-state: $value;
	animation-play-state: $value;
}
@mixin anim-timing-func($value: ease) {
	-webkit-animation-timing-function: $value;
	-moz-animation-timing-function: $value;
	-o-animation-timing-function: $value;
	animation-timing-function: $value;
}

/* Background */
@mixin bg($value: none) {
	background: $value;
}
@mixin bg-attc($value: scroll) {
	background-attachment: $value;
}
@mixin bg-blend-mode($value: normal) {
	background-blend-mode: $value;
}
@mixin bg-clip($value: border-box) {
	-webkit-background-clip: $value;
	-moz-background-clip: $value;
	background-clip: $value;
}
@mixin bg-color($value: transparent) {
	background-color: $value;
}
@mixin bg-image($value: none) {
	background-image: $value;
}
@mixin bg-origin($value: padding-box) {
	-webkit-background-origin: $value;
	background-origin: $value;
}
@mixin bg-position($value: 0% 0%) {
	background-position: $value;
}
@mixin bg-repeat($value: repeat) {
	background-repeat: $value;
}
@mixin bg-size($value: auto auto) {
	-webkit-background-size: $value;
	background-size: $value;
}

/* Border */
@mixin bdr($value: none) {
	border: $value;
}
@mixin bdr-top($value: none) {
	border-top: $value;
}
@mixin bdr-right($value: none) {
	border-right: $value;
}
@mixin bdr-bottom($value: none) {
	border-bottom: $value;
}
@mixin bdr-left($value: none) {
	border-left: $value;
}
@mixin bdr-collapse($value: separate) {
	border-collapse: $value;
}
@mixin bdr-color($value: #000) {
	border-color: $value;
}
@mixin bdr-radius($value: 0) {
	-webkit-border-radius: $value;
	-moz-border-radius: $value;
	border-radius: $value;
}
@mixin bdr-spacing($value: 0) {
	border-spacing: $value;
}
@mixin bdr-style($value: none) {
	border-style: $value;
}
@mixin bdr-width($value: medium) {
	border-width: $value;
}
@mixin bdr-bottom-width($value: medium) {
	border-bottom-width: $value;
}
@mixin bdr-bottom-color($value: #000) {
	border-bottom-color: $value;
}
@mixin bdr-top-color($value: #000) {
	border-top-color: $value;
}
@mixin bdr-right-color($value: #000) {
	border-right-color: $value;
}
@mixin bdr-bottom-color($value: #000) {
	border-bottom-color: $value;
}
@mixin bdr-left-color($value: #000) {
	border-left-color: $value;
}
@mixin bdr-image($value: initial) {
	border-image: $value;
}


/* Box Sizing */
@mixin box-sizing($value: content-box) {
	-webkit-box-sizing: $value;
	-moz-box-sizing: $value;
	box-sizing: $value;
}
@mixin box-shadow($value: none) {
	-webkit-box-shadow: $value;
	-moz-box-shadow: $value;
	box-shadow: $value;
}

/* Color */
@mixin colr($value) {
	color: $value;
}

/* Columns */
@mixin cols($value: auto auto) {
	columns: $value;
}
@mixin col-count($value: auto) {
	-webkit-column-count: $value;
	-moz-column-count: $value;
	column-count: $value;
}
@mixin col-gap($value: normal) {
	-webkit-column-gap: $value;
	-moz-column-gap: $value;
	column-gap: $value;
}
@mixin col-rule($value: medium none transparent) {
	column-rule: $value;
}
@mixin col-rule-color($value: transparent) {
	-webkit-column-rule-color: $value;
	-moz-column-rule-color: $value;
	column-rule-color: $value;
}
@mixin col-rule-style($value: none) {
	-webkit-column-rule-style: $value;
	-moz-column-rule-style: $value;
	column-rule-style: $value;
}
@mixin col-rule-width($value: medium) {
	-webkit-column-rule-width: $value;
	-moz-column-rule-width: $value;
	column-rule-width: $value;
}

/* Content */
@mixin cont($value: normal) {
	content: $value;
}

/* Counter */
@mixin cntr-inc($value: none) {
	counter-increment: $value;
}
@mixin cntr-reset($value: none) {
	counter-reset: $value;
}

/* Flex */
@mixin flx($value: 0 1 auto) {
	-moz-flex: $value;
	-ms-flex: $value;
	-webkit-flex: $value;
	flex: $value;
}
@mixin flx-direction($value: row) {
	-moz-flex-direction: $value;
	-ms-flex-direction: $value;
	-webkit-flex-direction: $value;
	flex-direction: $value;
}
@mixin flx-flow($value: row nowrap) {
	-moz-flex-flow: $value;
	-ms-flex-flow: $value;
	-webkit-flext-flow: $value;
	flex-flow: $value;
}
@mixin flx-grow($value: 0) {
	-moz-flex-grow: $value;
	-ms-flex-grow: $value;
	-webkit-flex-grow: $value;
	flex-grow: $value;
}
@mixin flx-shrink($value: 1) {
	-moz-flex-shrink: $value;
	-ms-flex-shrink: $value;
	-webkit-flex-shrink: $value;
	flex-shrink: $value;
}
@mixin flx-wrap($value: nowrap) {
	-moz-flex-wrap: $value;
	-ms-flex-wrap: $value;
	-webkit-flex-wrap: $value;
	flex-wrap: $value;
}

/* Float */
@mixin flt($value: none) {
	float: $value;
}

/* Font */
@mixin fnt($value) {
	font: $value;
}
@mixin fnt-family($value) {
	font-family: $value;
}
@mixin fnt-kerning($value: auto) {
	-moz-font-kerning: $value;
	-ms-font-kerning: $value;
	-webkit-font-kerning: $value;
	font-kerning: $value;
}
@mixin fnt-size($value: medium) {
	font-size: $value;
}
@mixin fnt-style($value: normal) {
	font-style: $value;
}
@mixin fnt-weight($value: normal) {
	font-weight: $value;
}
@mixin fnt-variant($value: normal) {
	font-variant: $value;
}
@mixin fnt-stretch($value: normal) {
	font-stretch: $value;
}

/* Misc */
@mixin display($value: block) {
	display: $value;
}
@mixin lttr-spacing($value: normal) {
	letter-spacing: $value;
}
@mixin line-h($value: normal) {
	line-height: $value;
}
@mixin list-style($value: disc outside none) {
	list-style: $value;
}
@mixin list-style-img($value: none) {
	list-style-image: $value;
}
@mixin list-style-pos($value: outside) {
	list-style-position: $value;
}
@mixin list-style-type($value: disc) {
	list-style-type: disc;
}
@mixin opc($value: 1) {
	opacity: $value;
}
@mixin odr($value: 0) {
	-moz-order: $value;
	-ms-order: $value;
	-webkit-order: $value;
	order: $value;
}
@mixin outln($value: none) {
	outline: $value;
}
@mixin outln-color($value: transparent) {
	outline-color: $value;
}
@mixin outln-style($value: none) {
	outline-style: $value;
}
@mixin outln-width($value: medium) {
	outline-width: $value;
}
@mixin resz($value: none) {
	-moz-resize: $value;
	resize: $value;
}
@mixin valign($value: baseline) {
	vertical-align: $value;
}
@mixin visibly($value: visible) {
	visibility: $value;
}
@mixin wht-spc($value: normal) {
	white-space: $value;
}
@mixin wd-spacing($value: normal) {
	word-spacing: $value;
}
@mixin wd-wrap($value: normal) {
	-moz-word-wrap: $value;
	-ms-word-wrap: $value;
	word-wrap: $value;
}
@mixin zidx($value: auto) {
	z-index: $value;
}
@mixin zm($value: 1) {
	zoom: $value;
}
@mixin clr($value: none) {
	clear: $value;
}
@mixin csr($value: default) {
	cursor: $value;
}
@mixin appr($value: none) {
	-webkit-appearance: $value;
	-moz-appearance: $value;
	appearance: $value;
}

/* Margin */
@mixin mgn($value: 0) {
	margin: $value;
}
@mixin mgn-top($value: 0) {
	margin-top: $value;
}
@mixin mgn-right($value: 0) {
	margin-right: $value;
}
@mixin mgn-bottom($value: 0) {
	margin-bottom: $value;
}
@mixin mgn-left($value: 0) {
	margin-left: $value;
}

/* Sizing */
@mixin max-w($value: none) {
	max-width: $value;
}
@mixin max-h($value: none) {
	max-height: $value;
}
@mixin min-w($value: 0) {
	min-width: $value;
}
@mixin min-h($value: 0) {
	min-height: $value;
}
@mixin w($value: auto) {
	width: $value;
}
@mixin h($value: auto) {
	height: $value;
}
@mixin size($w: auto, $h: auto) {
	@include w($w);
	@include h($h);
}
@mixin square($length: auto) {
	@include size($length, $length);
}

/* Overflow */
@mixin ovflow($value: visible) {
	overflow: $value;
}
@mixin ovflow-x($value: visible) {
	overflow-x: $value;
}
@mixin ovflow-y($value: visible) {
	overflow-y: $value;
}

/* Padding */
@mixin padd($value: 0) {
	padding: $value;
}
@mixin padd-top($value: 0) {
	padding-top: $value;
}
@mixin padd-right($value: 0) {
	padding-right: $value;
}
@mixin padd-bottom($value: 0) {
	padding-bottom: $value;
}
@mixin padd-left($value: 0) {
	padding-left: $value;
}

/* Position */
@mixin pos($value: static) {
	position: $value;
}
@mixin t($value: auto) {
	top: $value;
}
@mixin r($value: auto) {
	right: $value;
}
@mixin b($value: auto) {
	bottom: $value;
}
@mixin l($value: auto) {
	left: $value;
}
@mixin anchor($top: auto, $right: auto, $bottom: auto, $left: auto) {
	@include t($top);
	@include r($right);
	@include b($bottom);
	@include l($left);
}
@mixin anchor-all($value: auto) {
	@include t($value);
	@include r($value);
	@include b($value);
	@include l($value);
}

/* Text */
@mixin txt-align($value: start) {
	text-align: $value;
}
@mixin txt-decor($value: none) {
	text-decoration: $value;
}
@mixin txt-indent($value: 0) {
	text-indent: $value;
}
@mixin txt-overflow($value: clip) {
	-ms-text-overflow: $value;
	text-overflow: $value;
}
@mixin txt-shadow($value: none) {
	text-shadow: $value;
}
@mixin txt-transform($value: none) {
	text-transform: $value;
}
@mixin transfm($value: none) {
	-moz-transform: $value;
	-ms-transform: $value;
	-webkit-transform: $value;
	-o-transform: $value;
	transform: $value;
}
@mixin transfm-origin($value: 0 0) {
	-webkit-transform-origin: $value;
	-moz-transform-origin: $value;
	-ms-transform-origin: $value;
	-o-transform-origin: $value;
	transform-origin: $value;
}
@mixin transtn($value: none) {
	-moz-transition: $value;
	-ms-transition: $value;
	-webkit-transition: $value;
	-o-transition: $value;
	transition: $value;
}
@mixin ltr-spc($value: normal) {
	letter-spacing: $value;
}

/* Flex */
@mixin flx-flow($value: row nowrap) {
	-webkit-flex-flow: $value;
	flex-flow: $value;
}
@mixin flx-grow($value: 0) {
	flex-grow: $value;
}
@mixin flx-wrap($value: nowrap) {
	flex-wrap: $value;
}
@mixin jstfy-cont($value: flex-start) {
	justify-content: $value;
}
@mixin alg-cont($value: stretch) {
	align-content: $value;
}
@mixin alg-item($value: flex-start) {
	align-items: $value;
}
@mixin alg-self($value: flex-start) {
	align-self: $value;
}

/* Transition */
@mixin trans-duration($time: .1s) {
	-webkit-transition-duration: $time;
	-moz-transition-duration: $time;
	-ms-transition-duration: $time;
	-o-transition-duration: $time;
	transition-duration: $time;
}
@mixin trans-timing($value: ease-out) {
	-webkit-transition-timing-function: $value;
	-moz-transition-timing-function: $value;
	-ms-transition-timing-function: $value;
	-o-transition-timing-function: $value;
	transition-timing-function: $value;
}

/*Filter*/
@mixin fltr($value: none) {
	-webkit-filter: $value;
	-moz-filter: $value;
	-o-filter: $value;
	-ms-filter: $value;
	filter: $value;
}


/*Mixins for quick use in code*/
@mixin padd-vert($value: 0) {
	@include padd-top($value);
	@include padd-bottom($value);
}
@mixin padd-horz($value: 0) {
	@include padd-left($value);
	@include padd-right($value);
}
@mixin mgn-vert($size: auto) {
	@include mgn-top($size);
	@include mgn-bottom($size);
}
@mixin mgn-horz($size: auto) {
	@include mgn-left($size);
	@include mgn-right($size);
}
@mixin clearfix {
	&:before, &:after {
		@include display(table);
		@include cont(" ");
	}
	&:after {
		@include clr(both);
	}
}
@mixin pos-tl($posTop: auto, $posLeft: auto) {
	@include t($posTop);
	@include l($posLeft);
}
@mixin pos-tr($posTop: auto, $posRight: auto) {
	@include t($posTop);
	@include r($posRight);
}
@mixin pos-bl($posBottom: auto, $posLeft: auto) {
	@include b($posBottom);
	@include l($posLeft);
}
@mixin pos-br($posBottom: auto, $posRight: auto) {
	@include b($posBottom);
	@include l($posRight);
}
@mixin gradient-vert($color1, $color2) {
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#2a4e96+0,131949+100 */
	background: $color1; /* Old browsers */
	background: -moz-linear-gradient(top,  #{$color1} 0%, #{$color2} 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top,  #{$color1} 0%, #{$color2} 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom,  #{$color1} 0%, #{$color2} 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$color1}', endColorstr='#{$color2}',GradientType=0 ); /* IE6-9 */
}


/* FLEX PROP */
@mixin flex-container-prop() {
	/*Style as a container*/
	@include display(flex);
	@include jstfy-cont(flex-start);
	@include alg-item(stretch);
	@include alg-cont(flex-start);
}
@mixin flex-column-container-prop() {
	@include flex-container-prop();
	@include flx-flow(column nowrap);
}
@mixin flex-row-container-prop() {
	@include flex-container-prop();
	@include flx-flow(row nowrap);
}
@mixin flex-child-prop($grow: 1, $shrink: 0, $highVal: auto) {
	@include flx(#{$grow} #{$shrink} #{$highVal});
}

/* ANIMATION */
@mixin transtn-very-fast() {
	@include transtn(all .15s $EASE-OUT-ANIM);
}
@mixin transtn-fast() {
	@include transtn(all .25s $EASE-OUT-ANIM);
}
@mixin transtn-normal() {
	@include transtn(all .4s $EASE-OUT-ANIM);
}
@mixin transtn-slow() {
	@include transtn(all .8s $EASE-OUT-ANIM);
}
@mixin transtn-custom($attr: all, $duration: 1s) {
	@include transtn(#{$attr} #{$duration} $EASE-OUT-ANIM);
}


/* MISC */
@mixin bg-image-prop() {
	@include bg-repeat(no-repeat);
	@include bg-position(center);
	@include bg-size(cover);
}
@mixin triangle-right($bdrWidth: 10px, $bdrColor: #000) {
	@include bdr-top(solid $bdrWidth transparent);
	@include bdr-right(solid $bdrWidth transparent);
	@include bdr-bottom(solid $bdrWidth transparent);
	@include bdr-left(solid $bdrWidth $bdrColor);
}
@mixin triangle-left($bdrWidth: 10px, $bdrColor: #000) {
	@include bdr-top(solid $bdrWidth transparent);
	@include bdr-right(solid $bdrWidth $bdrColor);
	@include bdr-bottom(solid $bdrWidth transparent);
	@include bdr-left(solid $bdrWidth transparent);
}
@mixin triangle-down($bdrWidth: 10px, $bdrColor: #000) {
	@include bdr-top(solid $bdrWidth $bdrColor);
	@include bdr-right(solid $bdrWidth transparent);
	@include bdr-bottom(solid $bdrWidth transparent);
	@include bdr-left(solid $bdrWidth transparent);
}
@mixin triangle-up($bdrWidth: 10px, $bdrColor: #000) {
	@include bdr-top(solid $bdrWidth transparent);
	@include bdr-right(solid $bdrWidth transparent);
	@include bdr-bottom(solid $bdrWidth $bdrColor);
	@include bdr-left(solid $bdrWidth transparent);
}


$DEFAULT-SPINNER-SHADOW: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
@mixin spinner-prop($size: 64px, $bg-color: rgb(255, 255, 255), $padd: 8px, $shdw: $DEFAULT-SPINNER-SHADOW, $wrapper-margin: 10px 20px 5px) {
	.spinner {
		margin: $wrapper-margin;
		text-align: center;
		display: inline-block;
	}

	.spinner svg {
		background-color: $bg-color;
		border-radius: 50%;
		padding: $padd;
		box-shadow: $shdw;
	}

	.qp-circular-loader {
		width: $size;
		/* 2*RADIUS + STROKEWIDTH */

		height: $size;
		/* 2*RADIUS + STROKEWIDTH */

		stroke-width: 3px;
	}

	.qp-circular-loader-path {
		stroke-dasharray: 58.9;
		/* 2*RADIUS*PI * ARCSIZE/360 */

		stroke-dashoffset: 58.9;
		/* 2*RADIUS*PI * ARCSIZE/360 */
		/* hides things initially */
	}
	/* SVG elements seem to have a different default origin */

	.qp-circular-loader,
	.qp-circular-loader * {
		-webkit-transform-origin: 50% 50%;
		transform-origin: 50% 50%;
	}
	/* Rotating the whole thing */

	.qp-circular-loader {
		-webkit-animation-name: rotate;
		animation-name: rotate;
		-webkit-animation-duration: 1568.63ms;
		animation-duration: 1568.63ms;
		/* 360 * ARCTIME / (ARCSTARTROT + (360-ARCSIZE)) */

		-webkit-animation-iteration-count: infinite;
		animation-iteration-count: infinite;
		-webkit-animation-timing-function: linear;
		animation-timing-function: linear;
	}
	/* Filling and unfilling the arc */
	.qp-circular-loader-path {
		-webkit-animation-name: fillunfill, rot, colors;
		animation-name: fillunfill, rot, colors;
		-webkit-animation-duration: 1333ms, 5332ms, 5332ms;
		animation-duration: 1333ms, 5332ms, 5332ms;
		/* ARCTIME, 4*ARCTIME, 4*ARCTIME */

		-webkit-animation-iteration-count: infinite, infinite, infinite;
		animation-iteration-count: infinite, infinite, infinite;
		-webkit-animation-timing-function: cubic-bezier(0.4, 0.0, 0.2, 1), steps(4), linear;
		animation-timing-function: cubic-bezier(0.4, 0.0, 0.2, 1), steps(4), linear;
		-webkit-animation-play-state: running, running, running;
		animation-play-state: running, running, running;
		-webkit-animation-fill-mode: forwards;
		animation-fill-mode: forwards;
	}
} 

@mixin material-icon-prop() {
	font-family: 'Material Icons';
	font-weight: normal;
	font-style: normal;
	font-size: 24px;  /* Preferred icon size */
	display: inline-block;
	line-height: 1;
	text-transform: none;
	letter-spacing: normal;
	word-wrap: normal;
	white-space: nowrap;
	direction: ltr;

	/* Support for all WebKit browsers. */
	-webkit-font-smoothing: antialiased;
	/* Support for Safari and Chrome. */
	text-rendering: optimizeLegibility;

	/* Support for Firefox. */
	-moz-osx-font-smoothing: grayscale;

	/* Support for IE. */
	font-feature-settings: 'liga';
}