@import "../includes";

$LOGIN-BOX-H-STATE-1: 400px;
$LOGIN-BOX-H-STATE-2: 300px;
$LOGIN-BOX-H-STATE-3: $LOGIN-BOX-H-STATE-2 + 40px;

@keyframes login-panel-welcome {
	0% {
		width: 260px;
		height: $LOGIN-BOX-H-STATE-1;
	}
	100% {
		width: 300px;
		height: $LOGIN-BOX-H-STATE-2;
	}
}

@keyframes proceed-to-main {
	0% {
		width: 300px;
		height: $LOGIN-BOX-H-STATE-2;
		opacity: 1;
	}
	100% {
		width: 400px;
		height: $LOGIN-BOX-H-STATE-3;
		opacity: 0;
	}
}

login {
	position: fixed;
	width: auto;
	height: auto;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}
.login-bg-layer {
	position: absolute;
	width: auto;
	height: auto;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	opacity: .65;
	z-index: 1;
	transition: all 500ms $EASE-OUT;

	&.transitioning {
		opacity: 0;
	}

	.logo-box {
		position: absolute;
		bottom: 20px;
		left: 20px;

		img {
			max-height: 22px;
		}		
	}
}
.login-panel {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 2;
	transform: translate3d(-50%, -50%, 0);
	transform-origin: 50% 50%;
	width: 280px;
	height: $LOGIN-BOX-H-STATE-1;
	transition: all 500ms $EASE-OUT;

	.title {
		padding-bottom: 10px;
	}

	.welcome-user-wrapper {
		display: none;
		opacity: 0;
		text-align: center;

		.image-block {
			display: block;
			width: 84px;
			height: 84px;
			background-color: #ccc;
			border-radius: 50%;
			margin: 0 auto 20px;
		}

		.spinner {
			@include spinner-prop(32px);
			margin-top: 20px;
		}
	}

	&.welcome {
		animation: login-panel-welcome 400ms $EASE-OUT forwards;

		.title, .form-wrapper {
			display: none;
		}

		.welcome-user-wrapper {
			display: block;
			animation: OPC-zero-to-one 300ms $EASE-OUT .3s forwards;
		}
	}

	&.transitioning {
		animation: proceed-to-main 500ms $EASE-OUT forwards;
		
		.welcome-user-wrapper {
			animation: OPC-zero-to-one 300ms $EASE-OUT .3s forwards;
		}
	}
}

.password-strength-bar {
	height: 20px;
	margin-top: 3px;
	margin-bottom: 5px;

	.color-bar {
		position: relative;
		display: inline-block;
		width: 180px;
		height: 6px;
		background-color: #ddd;
		vertical-align: middle;

		.inner-bar {		
			position: absolute;
			top: 0;
			left: 0;
			width: 0;
			height: 6px;
			transform-origin: 0 50%;
			transition: all 300ms $EASE-OUT;
		}
	}

	.text-block {
		font-size: 12px;
		margin-left: 3px;
		transform: translateY(2px);
	}

	&.weak-str {
		.text-block {
			color: #EF5350;
		}
		.inner-bar {
			width: 33.333333%;
			background-color: #EF5350;
		}
	}
	&.med-str {
		.text-block {
			color: #F57C00;
		}
		.inner-bar {
			width: 66.666667%;
			background-color: #F57C00;
		}
	}
	&.good-str {
		.text-block {
			color: #43A047;
		}
		.inner-bar {
			width: 100%;
			background-color: #43A047;
		}
	}
}

.cms-logo-box {
	display: block;
	width: 100%;
	margin-bottom: 20px;

	img {
		max-width: 100%;
		max-height: 34px;
		display: block;
		margin: 0 auto;
	}
}