@import "../_includes";

.snack-bar {
	position: fixed;
	z-index: 5003;
	width: auto;
	min-width: 320px;
	height: auto;
	padding: 15px 50px 15px 30px;
	bottom: 0;
	left: 50%;
	transition: all 400ms $EASE-OUT;
	transform: translate3d(-50%, 100%, 0);
	font-size: 14px;

	&.displayed {
		transform: translate3d(-50%, 0, 0);
	}

	&.normal {
		background-color: rgba(0, 0, 0, 0.870588);
		color: #fff;
	}
	&.error {
		background-color: #E53935;
		color: #fff;
	}
	&.warning {
		background-color: #FF5722;
		color: #fff;
	}
	&.success {
		background-color: #558B2F;
		color: #fff;
	}

	.type-icon {
		vertical-align: middle;
		transform: translateY(-2px);
		margin-right: 7px;
	}
	.message {
		display: flex;
		flex-flow: row nowrap;
		align-items: stretch;
		justify-content: flex-start;

		.message-body {
			flex: 0 0 calc(100% - 30px);
			width: calc(100% - 30px);
			align-self: flex-start;
		}

		.type-icon {
			flex: 0 0 30px;
			width: 30px;
			height: 20px;
			line-height: 20px;
			align-self: flex-start;
		}
	}

	.close-btn {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 15px;
		cursor: pointer;
		@include clearfix();
		opacity: .5;
		
		i {
			float: left;
			width: 24px;
			height: 24px;
			text-align: center;
			line-height: 24px;
		}

		&:hover {
			opacity: 1;
		}
	}
}