@import "abstracts/_animation";
@import "abstracts/_functions";
@import "abstracts/_mixins";
@import "abstracts/_placeholders";
@import "abstracts/_variables";


/* DISPLAY */
@mixin block {
	@include display(block);
}
@mixin inline-block {
	@include display(inline-block);
}
@mixin none {
	@include display(none);
}

/* PADDING */
@mixin no-padd {
	@include padd(0);
}
@mixin padd-vert($value: 0) {
	@include padd-top($value);
	@include padd-bottom($value);
}
@mixin padd-horz($value: 0) {
	@include padd-left($value);
	@include padd-right($value);
}
@mixin padd-xsml {
	@include padd($XSML-SPC);
}
@mixin padd-sml {
	@include padd($XML-SPC);
}
@mixin padd-med {
	@include padd($MED-SPC);
}
@mixin padd-xmed {
	@include padd($XMED-SPC);
}
@mixin padd-lg {
	@include padd($LG-SPC);
}
@mixin padd-xlg {
	@include padd($XLG-SPC);
}
@mixin padd-xxlg {
	@include padd($XXLG-SPC);
}
@mixin padd-left-xsml {
	@include padd-left($XSML-SPC);
}
@mixin padd-left-sml {
	@include padd-left($XML-SPC);
}
@mixin padd-left-med {
	@include padd-left($MED-SPC);
}
@mixin padd-left-xmed {
	@include padd-left($XMED-SPC);
}
@mixin padd-left-lg {
	@include padd-left($LG-SPC);
}
@mixin padd-left-xlg {
	@include padd-left($XLG-SPC);
}
@mixin padd-left-xxlg {
	@include padd-left($XXLG-SPC);
}
@mixin padd-right-xsml {
	@include padd-right($XSML-SPC);
}
@mixin padd-right-sml {
	@include padd-right($XML-SPC);
}
@mixin padd-right-med {
	@include padd-right($MED-SPC);
}
@mixin padd-right-xmed {
	@include padd-right($XMED-SPC);
}
@mixin padd-right-lg {
	@include padd-right($LG-SPC);
}
@mixin padd-right-xlg {
	@include padd-right($XLG-SPC);
}
@mixin padd-right-xxlg {
	@include padd-right($XXLG-SPC);
}
@mixin padd-top-xsml {
	@include padd-top($XSML-SPC);
}
@mixin padd-top-sml {
	@include padd-top($XML-SPC);
}
@mixin padd-top-med {
	@include padd-top($MED-SPC);
}
@mixin padd-top-xmed {
	@include padd-top($XMED-SPC);
}
@mixin padd-top-lg {
	@include padd-top($LG-SPC);
}
@mixin padd-top-xlg {
	@include padd-top($XLG-SPC);
}
@mixin padd-top-xxlg {
	@include padd-top($XXLG-SPC);
}
@mixin padd-bottom-xsml {
	@include padd-bottom($XSML-SPC);
}
@mixin padd-bottom-sml {
	@include padd-bottom($XML-SPC);
}
@mixin padd-bottom-med {
	@include padd-bottom($MED-SPC);
}
@mixin padd-bottom-xmed {
	@include padd-bottom($XMED-SPC);
}
@mixin padd-bottom-lg {
	@include padd-bottom($LG-SPC);
}
@mixin padd-bottom-xlg {
	@include padd-bottom($XLG-SPC);
}
@mixin padd-bottom-xxlg {
	@include padd-bottom($XXLG-SPC);
}
@mixin padd-vert-xsml {
	@include padd-vert($XSML-SPC);
}
@mixin padd-vert-sml {
	@include padd-vert($XML-SPC);
}
@mixin padd-vert-med {
	@include padd-vert($MED-SPC);
}
@mixin padd-vert-xmed {
	@include padd-vert($XMED-SPC);
}
@mixin padd-vert-lg {
	@include padd-vert($LG-SPC);
}
@mixin padd-vert-xlg {
	@include padd-vert($XLG-SPC);
}
@mixin padd-vert-xxlg {
	@include padd-vert($XXLG-SPC);
}
@mixin padd-horz-xsml {
	@include padd-left($XSML-SPC);
	@include padd-right($XSML-SPC);
}
@mixin padd-horz-sml {
	@include padd-left($XML-SPC);
	@include padd-right($XML-SPC);
}
@mixin padd-horz-med {
	@include padd-left($MED-SPC);
	@include padd-right($MED-SPC);
}
@mixin padd-horz-xmed {
	@include padd-left($XMED-SPC);
	@include padd-right($XMED-SPC);
}
@mixin padd-horz-lg {
	@include padd-left($LG-SPC);
	@include padd-right($LG-SPC);
}
@mixin padd-horz-xlg {
	@include padd-left($XLG-SPC);
	@include padd-right($XLG-SPC);
}
@mixin padd-horz-xxlg {
	@include padd-left($XXLG-SPC);
	@include padd-right($XXLG-SPC);
}


/* MARGIN */
@mixin mgn-xsml {
	@include mgn($XSML-SPC);
}
@mixin mgn-sml {
	@include mgn($XML-SPC);
}
@mixin mgn-med {
	@include mgn($MED-SPC);
}
@mixin mgn-xmed {
	@include mgn($XMED-SPC);
}
@mixin mgn-lg {
	@include mgn($LG-SPC);
}
@mixin mgn-xlg {
	@include mgn($XLG-SPC);
}
@mixin mgn-xxlg {
	@include mgn($XXLG-SPC);
}
@mixin mgn-left-xsml {
	@include mgn-left($XSML-SPC);
}
@mixin mgn-left-sml {
	@include mgn-left($XML-SPC);
}
@mixin mgn-left-med {
	@include mgn-left($MED-SPC);
}
@mixin mgn-left-xmed {
	@include mgn-left($XMED-SPC);
}
@mixin mgn-left-lg {
	@include mgn-left($LG-SPC);
}
@mixin mgn-left-xlg {
	@include mgn-left($XLG-SPC);
}
@mixin mgn-left-xxlg {
	@include mgn-left($XXLG-SPC);
}
@mixin mgn-right-xsml {
	@include mgn-right($XSML-SPC);
}
@mixin mgn-right-sml {
	@include mgn-right($XML-SPC);
}
@mixin mgn-right-med {
	@include mgn-right($MED-SPC);
}
@mixin mgn-right-xmed {
	@include mgn-right($XMED-SPC);
}
@mixin mgn-right-lg {
	@include mgn-right($LG-SPC);
}
@mixin mgn-right-xlg {
	@include mgn-right($XLG-SPC);
}
@mixin mgn-right-xxlg {
	@include mgn-right($XXLG-SPC);
}
@mixin mgn-top-xsml {
	@include mgn-top($XSML-SPC);
}
@mixin mgn-top-sml {
	@include mgn-top($XML-SPC);
}
@mixin mgn-top-med {
	@include mgn-top($MED-SPC);
}
@mixin mgn-top-xmed {
	@include mgn-top($XMED-SPC);
}
@mixin mgn-top-lg {
	@include mgn-top($LG-SPC);
}
@mixin mgn-top-xlg {
	@include mgn-top($XLG-SPC);
}
@mixin mgn-top-xxlg {
	@include mgn-top($XXLG-SPC);
}
@mixin mgn-bottom-xsml {
	@include mgn-bottom($XSML-SPC);
}
@mixin mgn-bottom-sml {
	@include mgn-bottom($XML-SPC);
}
@mixin mgn-bottom-med {
	@include mgn-bottom($MED-SPC);
}
@mixin mgn-bottom-xmed {
	@include mgn-bottom($XMED-SPC);
}
@mixin mgn-bottom-lg {
	@include mgn-bottom($LG-SPC);
}
@mixin mgn-bottom-xlg {
	@include mgn-bottom($XLG-SPC);
}
@mixin mgn-bottom-xxlg {
	@include mgn-bottom($XXLG-SPC);
}
@mixin mgn-vert($size: auto) {
	@include mgn-top($size);
	@include mgn-bottom($size);
}
@mixin mgn-vert-xsml {
	@include mgn-vert($XSML-SPC);
}
@mixin mgn-vert-sml {
	@include mgn-vert($XML-SPC);
}
@mixin mgn-vert-med {
	@include mgn-vert($MED-SPC);
}
@mixin mgn-vert-xmed {
	@include mgn-vert($XMED-SPC);
}
@mixin mgn-vert-lg {
	@include mgn-vert($LG-SPC);
}
@mixin mgn-vert-xlg {
	@include mgn-vert($XLG-SPC);
}
@mixin mgn-vert-xxlg {
	@include mgn-vert($XXLG-SPC);
}
@mixin mgn-horz($size: auto) {
	@include mgn-left($size);
	@include mgn-right($size);
}
@mixin mgn-horz-xsml {
	@include mgn-horz($XSML-SPC);
}
@mixin mgn-horz-sml {
	@include mgn-horz($XML-SPC);
}
@mixin mgn-horz-med {
	@include mgn-horz($MED-SPC);
}
@mixin mgn-horz-xmed {
	@include mgn-horz($XMED-SPC);
}
@mixin mgn-horz-lg {
	@include mgn-horz($LG-SPC);
}
@mixin mgn-horz-xlg {
	@include mgn-horz($XLG-SPC);
}
@mixin mgn-horz-xxlg {
	@include mgn-horz($XXLG-SPC);
}
@mixin no-mgn {
	@include mgn(0);
}


/* TEXT */
@mixin txt-center {
	@include txt-align(center);
}
@mixin txt-left {
	@include txt-align(left);
}
@mixin txt-right {
	@include txt-align(right);
}
@mixin txt-justify {
	@include txt-align(justify);
}
@mixin txt-truncate {
	@include wht-spc(nowrap);
	@include ovflow(hidden);
	@include txt-overflow(ellipsis);
}
@mixin txt-bold {
	@include fnt-weight(bold);
}
@mixin txt-no-underline {
	@include txt-decor(none);
}
@mixin fnt-xsml {
	@include fnt-size(10pt);
}
@mixin fnt-sml {
	@include fnt-size(11pt);
}
@mixin fnt-med {
	@include fnt-size(12pt);
}
@mixin fnt-xmed {
	@include fnt-size(14pt);
}
@mixin fnt-lg {
	@include fnt-size(16pt);
}
@mixin fnt-xlg {
	@include fnt-size(20pt);
}
@mixin fnt-white{
	@include colr(#FFF !important);
}
@mixin fnt-italic {
	@include fnt-style(italic);
}
@mixin fnt-underline {
	@include txt-decor(underline);
}

/* POSITIONING */
@mixin clearfix {
	&:before, &:after {
		@include display(table);
		@include cont(" ");
	}
	&:after {
		@include clr(both);
	}
}
@mixin f-left {
	@include flt(left);
}
@mixin f-right {
	@include flt(right);
}
@mixin pos-tl($t: auto, $l: auto) {
	@include t($t);
	@include l($l);
}
@mixin pos-tr($t: auto, $r: auto) {
	@include t($t);
	@include r($r);
}
@mixin pos-bl($b: auto, $l: auto) {
	@include b($b);
	@include l($l);
}
@mixin pos-br($b: auto, $r: auto) {
	@include b($b);
	@include l($r);
}
@mixin pos-relative {
	@include pos(relative);
}
@mixin pos-absolute {
	@include pos(absolute);
}
@mixin fixed-top {
	@include anchor(0,0,auto,0);
}
@mixin fixed-bottom {
	@include anchor(auto,0,0,0);
}
@mixin fixed-left {
	@include anchor(0,auto,0,0);
}
@mixin fixed-right {
	@include anchor(0,0,0,auto);
}

/*UTILITY*/
@mixin full-w {
	@include w(100%);
}
@mixin pointer {
	@include csr(pointer);
}
@mixin no-select {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
@mixin gradient-vert($color1, $color2) {
	background: $color1; /* Old browsers */
	background: -moz-linear-gradient(top,  $color1 0%, $color2 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top,  $color1 0%,$color2 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom,  $color1 0%,$color2 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$color1}', endColorstr='#{$color2}',GradientType=0 ); /* IE6-9 */
}
@mixin mobile-scroll {
	-webkit-overflow-scrolling: touch;
}
@mixin no-mobile-scroll {
	-webkit-overflow-scrolling: auto;
}
@mixin no-ovflow {
	@include ovflow(hidden);
}
@mixin no-mobile-tap {
	-webkit-tap-highlight-color: rgba(0,0,0,0);
}

/* FLEX PROP */
@mixin flex-container-prop() {
	@include display(flex);
	@include jstfy-cont(flex-start);
	@include alg-item(stretch);
	@include alg-cont(flex-start);
}
@mixin flex-column-container-prop() {
	@include flex-container-prop();
	@include flx-flow(column nowrap);
}
@mixin flex-row-container-prop() {
	@include flex-container-prop();
	@include flx-flow(row nowrap);
}
@mixin flex-child-prop($grow: 1, $shrink: 0, $hgt: auto) {
	@include flx($grow $shrink $hgt);
}


/* Font Weight */
@mixin fnt-thin {
	@include fnt-weight(100);
}
@mixin fnt-light {
	@include fnt-weight(300);
}
@mixin fnt-normal {
	@include fnt-weight(400);
}
@mixin fnt-medium {
	@include fnt-weight(500);
}
@mixin fnt-bold {
	@include fnt-weight(700);
}


/* ANIMATION */
@mixin transtn-very-fast() {
	@include transtn(all .15s $EASE-OUT);
}
@mixin transtn-fast() {
	@include transtn(all .25s $EASE-OUT);
}
@mixin transtn-bfast() {
	@include transtn(all $ANIM-TIME-BFAST $EASE-OUT);
}
@mixin transtn-normal() {
	@include transtn(all .4s $EASE-OUT);
}
@mixin transtn-slow() {
	@include transtn(all .8s $EASE-OUT);
}
@mixin transtn-custom($attr: all, $duration: 1s) {
	@include transtn($attr $duration $EASE-OUT);
}


/* VERTICAL ALIGNMENT */
@mixin valign-middle {
	@include valign(middle);
}