@import "../_includes";

$DLG-ANIM-OFFSET-Y: -40px;
$MODAL-OVERLAY-BG-COLOR: rgba(0, 0, 0, 0.4);
$MD-DIALOG-OVERLAY-TRANSTN: opacity 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
$MD-DLG-BOX-SHADOW: rgba(0, 0, 0, 0.247059) 0px 14px 45px, rgba(0, 0, 0, 0.219608) 0px 10px 18px;
$MD-DLG-BOX-FNT: Roboto, sans-serif;

@keyframes MODAL-SHOW {
    0% {
        opacity: 0;
        transform: translateY($DLG-ANIM-OFFSET-Y);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
@keyframes MODAL-HIDE {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY($DLG-ANIM-OFFSET-Y);
    }
}

@mixin modal-show() {
    opacity: 1;
    transform: translateY(0);
}

@mixin modal-hide() {
    opacity: 0;
    transform: translateY(-30px);
}

@mixin modal-close() {
    opacity: 0;
}

.md-dialog-overlay {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $MODAL-OVERLAY-BG-COLOR;
    transform: translateZ(0);
    opacity: 0;
    z-index: 1000;
    border: none;
    transition: all 500ms $EASE-OUT;
    animation: OPC-zero-to-one $ANIM-TIME-NRM $EASE-OUT-ANIM .05s forwards;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    will-change: opacity;
    &.closing {
        // opacity: 0);
        animation: OPC-one-to-zero $ANIM-TIME-NRM $EASE-OUT-ANIM forwards;
    }
}

.md-dialog-box {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    position: fixed;
    background-color: rgb(255, 255, 255);
    color: rgba(0, 0, 0, 0.870588);
    box-shadow: $MD-DLG-BOX-SHADOW;
    top: 50%;
    left: 50%;
    max-width: 400px;
    min-width: 240px;
    border-radius: 3px;
    transform-origin: center;
    @include modal-hide();
    &.size-md {
        max-width: 600px;
    }
    &.size-xmd {
        max-width: 720px;
    }
    &.size-lg {
        max-width: 800px;
    }
    &.size-xlg {
        max-width: 1000px;
    }
    &.opened {
        @include modal-show();
        animation: MODAL-SHOW $ANIM-TIME-BFAST $EASE-OUT-ANIM forwards;
    }
    &.closing {
        @include modal-show();
        animation: MODAL-HIDE $ANIM-TIME-BFAST $EASE-OUT-ANIM forwards;
    }
    &.animate-ready {
        transition: all 350ms $EASE-OUT;
    }
    &.no-buttons {
        .md-dialog-content {
            bottom: 0;
        }
    }
    &.full-expanded {
        max-width: initial !important;
        top: 0 !important;
        left: 0 !important;
        width: 100vw !important;
        height: 100vh !important;
        margin-left: 0 !important;
        margin-top: 0 !important;
        border-radius: 0;
    }

    // Custom typeahead
    .twitter-typeahead {
        &.pos-top {
            .tt-menu {
                top: auto !important;
                bottom: 100% !important;
            }
        }
    }

    .tt-menu {
        max-height: 160px;
        overflow-y: auto;

        &.max-item-show-3 {
            max-height: 120px;
        }
        &.max-item-show-4 {
            max-height: 160px;
        }
    }

    //Custom theme
    &.warning-box {
        .dlg-type-icon, .title-text {
            color: #FFA726;
        }
        .dlg-type-icon.after-maximize {
            transform: translateX(20px);
        }
    }
}

.md-dialog-title {
    position: relative;
    z-index: 2;
    font-family: $MD-DLG-BOX-FNT;
    margin: 0;
    padding: 12px 24px;
    color: rgba(0, 0, 0, 0.870588);
    font-size: 22px;
    line-height: 32px;
    font-weight: 400;
    border-bottom: none;
    @include no-select();
    @include txt-truncate();
    &.has-close-btn {
        padding-right: 45px;
    }

    .title-text {
        &.padd-left {
            padding-left: 20px;
        }
    }
}

$FLOAT-BTN-PANEL: 56px;
$DLG-HEADER-H: 56px;

.md-dialog-content {
    position: absolute;
    top: $DLG-HEADER-H;
    right: 0;
    bottom: 54px;
    left: 0;
    font-family: $MD-DLG-BOX-FNT;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.6);
    padding: 0 24px 20px;
    box-sizing: border-box;
    overflow: hidden;
    overflow-y: auto;
    z-index: 1;
    transition: height 400ms;

    &.has-top-panel {
        // top: $DLG-HEADER-H + $FLOAT-BTN-PANEL;
        // padding-top: 20px;

        .button-panel {
            // position: fixed;
            // top: $DLG-HEADER-H;
            // z-index: 10;
            background-color: #fff;
            right: 0;
            left: 0;
            // height: $FLOAT-BTN-PANEL;
            padding: 10px 15px;
            // box-shadow: 0 3px 4px rgba(0,0,0,0.24);
        }

        .alert-panel {
            position: fixed;
            top: 99%;
            z-index: 10;
            right: 0;
            left: 0;
            height: auto;
            padding: 10px 15px;
            text-align: center;
            border-top-right-radius: 0;
            border-top-left-radius: 0;
        }
    }

    &.auto-size {
        //position: relative;
        //top: auto;
        //right: auto;
        //bottom: auto;
        //left: auto;
        //overflow-y: hidden;
        height: 0;
        overflow-y: hidden;
        transition: height 400ms;
        &+.md-dialog-buttons {
            position: relative;
            top: auto;
            right: auto;
            bottom: auto;
            left: auto;
        }
    }

    .button-panel .btn {
        i {
            margin-right: 12px;
        }
        span {
            display: none;
        }
    }

    &.no-scroll {
        overflow-y: hidden;
    }
}

.md-dialog-buttons {
    position: absolute;
    top: auto;
    right: 0;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    padding: 8px;
    width: 100%;
    text-align: right;
    margin-top: 0;
    border-top: none;
    z-index: 2;
    @include no-select();
    @include no-mobile-tap();
    .md-button {
        color: rgb(0, 188, 212);
    }
    .btn {
        &+.btn {
            margin-left: 5px;
        }
    }
    .dialog-btn {
        text-transform: uppercase;
    }
}

.dialog-close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 2;
    cursor: pointer;

    i {
        float: left;
        width: 34px;
        height: 34px;
        border-radius: 50%;
        text-align: center;
        line-height: 34px;
    }
}
.dialog-size-btn {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 2;
    cursor: pointer;

    &.align-right {
        right: 10px;
    }

    .icon-btn {
        float: left;
        width: 34px;
        height: 34px;
        border-radius: 50%;
        text-align: center;
        line-height: 34px;
    }

    &:hover {
        .icon-btn {
            color: #448AFF;
        }
    }

    .maximize-btn, .minimize-btn {
        opacity: 0.2;

        &:hover {
            opacity: 1;
        }
    }

    &.minimized {
        .maximize-btn {
            display: block;
        }
        .minimize-btn {
            display: none;
        }
    }
    &.maximized {
        .maximize-btn {
            display: none;
        }
        .minimize-btn {
            display: block;
        }
    }
}

.dummy-box {
    position: fixed;
    top: auto !important;
    right: auto !important;
    bottom: auto !important;
    left: auto !important;
    width: auto;
    height: auto;
    display: block;
    opacity: 1 !important;
    z-index: 5000;
    transform: none !important;
    max-width: initial !important;
    max-height: initial !important;
    .modal-title,
    .modal-content,
    .modal-buttons {
        position: relative;
        top: auto;
        right: auto;
        bottom: auto;
        left: auto;
        transform: none;
    }
}

@media screen and (min-width: 85em) {
    .md-dialog-content {
        .button-panel .btn {
            i {
                margin-right: 0;
            }
            span {
                display: inline-block;
            }
        }
    }
}