//1. All abstract functions, mixins, variables etc.
@import 
	'_includes';

//2. All vendors and 3rd-party stylesheet files (all in scss extension)
@import 
	'vendors/normalize',
	'../../node_modules/bootstrap/scss/bootstrap',
	'../vendors/jquery_datatable/dataTables.bootstrap4.scss',
	'../vendors/jquery_datatable/select/select.bootstrap4.min.scss',
	'../vendors/bootstrap-taginput/bootstrap-tagsinput.scss',
	'vendors/cropper.scss',
	'../vendors/colorpicker/css/colorpicker.scss',
	'vendors/britecharts/common.scss',
	'vendors/britecharts/donut.scss',
	'vendors/britecharts/sparkline.scss',
	'vendors/britecharts/line.scss',
	'vendors/britecharts/donut.scss';

//3. Base styles
@import 
	'base/_reset',
	'base/_typography';

//4. The main/common layout. Header, footer, sidebar etc.
@import 
	'layout/_page',
	'layout/_header',
	'layout/_sidenav';

//5. Component styles. Form, buttons, inputs etc.
@import 
	'components/_forms',
	'components/_loader',
	'components/_buttons',
	'components/_dialog',
	'components/_snackbar',
	'components/_appnav',
	'components/_datatable',
	'components/_toggle',
	'components/_switches',
	'components/_ripple',
	'components/_typeahead',
	'components/_tab',
	'components/_directory-selector',
	'components/_tagsinput',
	'components/_datepicker',
	'components/_colorpicker',
	'components/_control-list',
	'components/_map',
	'components/_page-selector';

//6. Specific page styles
@import 
	'pages/_login',
	'pages/_page-content',
	'pages/_dashboard',
	'abstracts/_util_css';
	
//7. Default themes (this file will be able to override all of your previous styles)
@import 
	'themes/_default',
	'themes/_material';