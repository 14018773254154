@import "../includes";

@keyframes image-spinner {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.directory-selector-area {


	/*
	.dir-wrapper {
		float: left;
		padding: 5px;
		display: flex;
		flex-flow: row nowrap;
		align-items: flex-start;
		justify-content: flex-start;

		&:not(.edit-mode).active {
			background-color: #2962FF;
			color: #fff;

			i.item-type.item-type-dir {
				color: #fff;
			}
		}

		&:not(.active):hover {
			background-color: rgba(0,0,0,0.1);
		}

		i.item-type {
			flex: 0 0 24px;
			margin: 0 2px auto;
			width: 24px;
			height: 24px;
			cursor: pointer;

			&.item-type-dir {
				color: #448AFF;

				&:before {
					content: "folder";
				}
			}
			&.item-dir-open:before {
				content: "folder_open";
			}
		}
		.idc-icon {
			display: inline-block;
			width: 16px;
			height: 24px;
			line-height: 24px;
			transform: translateY(4px);
			line-height: 24px;
			text-align: left;
			font-size: 17px;
		}
		.directory-name {
			flex: 0 0 auto;
			height: 24px;
			line-height: 24px;
			padding: 0 6px;
			cursor: pointer;
			font-size: 14px;
			font-weight: 500;
		}
	}
	*/
}
.file-path-tip {
	padding-top: 10px;
	height: 100px;
}
.image-size-tip {
	margin-top: 8px;

	i {
		vertical-align: middle;
		transform: translateY(-2px);
	}
}
.tip-size-normal {
	small {
		font-size: .9rem;
	}
}
cmp-file-uploader {
	position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 20px 24px;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
	min-height: 500px;

	.form-wrapper {
		align-self: stretch;
		flex: 1 0 auto;
		display: flex;
		flex-flow: column nowrap;
		align-items: flex-start;

		.directory-list-area {
			flex: 1 0 auto;
    		width: 100%;
		}
	}
}

$FILE-ICON-W: 50%;
$FILE-ICON-W-MD: 33.333333%;
$FILE-ICON-W-XMD: 25%;
$FILE-ICON-W-LG: 20%;
$FILE-ICON-W-XLG: 16.666667%;
$DIR-ICON-SIZE: 56px;
$DIR-ICON-SIZE-MD: 80px;
$DIR-ICON-SIZE-XMD: 100px;
$DIR-ICON-SIZE-LG: 120px;


.directory-list-area {
	position: relative;
	display: block;
	overflow: hidden;
	overflow-y: auto;
	height: 380px;
	background-color: #EEEEEE;
	padding: 10px;

	cmp-dir-item {
		display: block;
		width: 100%;
		@include clearfix();
	}

	// ol {
	// 	float: left;

	// 	li {
	// 		position: relative;
	// 		display: block;

	// 		ol {
	// 			li {
	// 				position: relative;
	// 				padding-left: 20px;

	// 				&:before, &:after {
	// 					position: absolute;
	// 					content: "";
	// 					width: 1px;
	// 					height: 6px;
	// 					background-color: #aaa;
	// 				}

	// 				&:before {
	// 					height: 100%;
	// 					left: 16px;
	// 					top: -5px;
	// 				}
	// 				&:after {
	// 					transform-origin: 0 50%;
	// 					transform: rotate(90deg);
	// 					top: 13px;
	// 					left: 22px;
	// 					height: 10px;
	// 				}

	// 				&:last-child {
	// 					&:before {
	// 						height: 24px;
	// 					}
	// 				}
	// 			}
	// 		}
	// 	}
	// }

	.inner-wrapper {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		overflow: hidden;
		overflow-y: auto;
		background-color: #EEEEEE;
		padding: 10px;

		& > ol {
			display: flex;
			flex-flow: row wrap;
			align-items: flex-start;
			justify-content: flex-start;

			li {
				flex: 0 0 $FILE-ICON-W;
				width: $FILE-ICON-W;
			}
		}

		&.loading {
			& > ol {
				opacity: 0;
			}
		}
	}

	[file-dir-item] {
		@include clearfix();
		width: $FILE-ICON-W;
	}

	.dir-wrapper {
		position: relative;
		display: block;
		width: 100%;

		padding: 5px;
		@include clearfix();

		&:not(.edit-mode).active {
			background-color: #448AFF;
			color: #fff;

			i.item-type.item-type-dir {
				color: #fff;
			}
		}

		&:not(.active):hover {
			cursor: pointer;
			background-color: rgba(0,0,0,0.05);
		}

		.image-prop-warning {
			position: absolute;
			z-index: 3;
			top: 5px;
			left: 5px;
			overflow: hidden;
			@include clearfix();

			i {
				float: left;
				width: 24px;
				height: 24px;
				line-height: 24px;
				text-align: center;
				font-size: 22px;
				color: #fff;
				background-color: #F44336;
				border-radius: 50%;
				margin-right: 3px;
			}
		}

		.image-file-info {
			position: absolute;
			z-index: 3;
			top: 5px;
			right: 5px;
			opacity: .4;
			display: none;

			i {
				width: 24px;
				height: 24px;
				line-height: 24px;
				text-align: center;
				font-size: 22px;
				color: #000;
				background-color: #fff;
				border-radius: 50%;
			}
		}

		&[data-item-type="file"]:hover {
			.image-file-info {
				display: block;

				&:hover {
					opacity: .75;
				}
			}
		}

		i.item-type {
			display: block;
			margin: 0 auto;
			width: 100%;
			height: 56px;
			font-size: 56px;
			line-height: 56px;
			text-align: center;
			color: #848484;

			&.item-type-dir {
				color: #448AFF;

				&:before {
					content: "folder";
				}
			}
			&.item-dir-open:before {
				content: "folder_open";
			}
			&.item-type-file:before {
				content: "insert_drive_file";
			}
			&.item-video:before {
				content: 'movie';
			}
		}
		.directory-name {
			display: block;
			padding: 0 6px;
			font-size: 14px;
			font-weight: 400;
			text-align: center;
			word-wrap: break-word;

			&.selected {
				color: #E53935;
			}
		}

		&.new-dir {
			opacity: .5;

			i.item-type,
			.directory-name {
				color: #9E9E9E;
			}

			&:hover, &.adding {
				opacity: 1;
			}
		}

		.thumbnail-wrapper {
			display: block;
			max-width: 100%;
			padding: 7px;

			img {
				display: block;
				max-width: 100%;
				margin: 0 auto;
			}

			.spinner {
				display: block;
				margin: 0 auto;
			}

			&.selected {
				background-color: #E53935;
			}
		}

		i.item-type {
			height: $DIR-ICON-SIZE;
			font-size: $DIR-ICON-SIZE;
			line-height: $DIR-ICON-SIZE;
		}

		.crop-btn {
			display: none;
			position: absolute;
			z-index: 10;
			width: 40px;
			height: 40px;
			line-height: 40px;
			text-align: center;
			background-color: #fff;
			border-radius: 50%;
			box-shadow: bottom-shadow(1);
			top: 50%;
			left: 50%;
			transform: translate3d(-50%, -50%, 0);

			&:hover {
				background-color: #2979FF;
				color: #fff;
			}
		}

		&.elem-unavailable {
			opacity: .5;

			&:hover {
				opacity: 1;

				.crop-btn {
					display: block;
				}
			}
		}

		.file-edit-mode-panel {
			position: absolute;
			z-index: 5;
			top: 6px;
			left: 6px;
			right: 6px;

            i.file-edit-icon + i.file-edit-icon {
                margin-right: 5px;
            }

			i.file-edit-icon {
				cursor: pointer;
				width: 28px;
				height: 28px;
				text-align: center;
				line-height: 28px;
				font-size: 20px;
				border-radius: 50%;
				background-color: #fff;
				color: rgba(0,0,0,0.2);
				box-shadow: bottom-shadow(1);

				&.selected {
					background-color: #E53935;
					color: #fff;
				}

				&[role="rename-button"] {
					float: right;
					color: rgba(85, 139, 47, 0.49);

					&:hover {
						cursor: pointer;
						background-color: rgba(85, 139, 47, 1);
						color: #fff;
					}
				}

                &[role="crop-button"] {
                    float: right;
                    color: rgba(85, 139, 47, 0.49);

                    &:hover {
                        cursor: pointer;
                        background-color: rgba(85, 139, 47, 1);
                        color: #fff;
                    }
                }
			}
		}
	}
}
.directory-info-bar {
	display: flex;
	flex-flow: row nowrap;
	align-items: stretch;
	justify-content: flex-start;
	margin-bottom: 10px;

	.directory-label {
		flex: 1 0 auto;
		line-height: 36px;
		padding-right: 10px;
	}
	.browse-file {
		flex: 0 0 auto;
	}

	.dir-nav {
		display: flex;
		flex-flow: row nowrap;
		align-items: flex-start;
		justify-content: flex-start;
		margin-top: 3px;

		li {
			flex: 0 0 auto;
			@include clearfix();

			.nav-item {
				float: left;
				height: 28px;
				line-height: 28px;
				padding: 0 6px;
				font-size: 1rem;
				color: rgba(0,0,0,0.5);

				&:not(.active):hover {
					cursor: pointer;
					background-color: rgba(0,0,0,0.05);
				}

				&.active {
					color: rgba(0,0,0,.87);
				}
			}

			& + li {
				&:before {
					@include material-icon-prop();
					content: "keyboard_arrow_right";
					float: left;
					vertical-align: middle;
					width: 14px;
					height: 28px;
					line-height: 28px;
					text-align: center;
					text-align: left;
					text-indent: -5px;
					margin: 0 4px;
				}
			}
		}
	}

	.selected-file-label {
		flex: 0 0 auto;
		line-height: 36px;
		padding-right: 10px;
		padding-left: 10px;
		overflow: hidden;
		text-overflow: ellipsis;
		margin-bottom: 0;
	}
}
.empty-directory-message {
	font-size: 1.2rem;
	font-weight: 500;
	color: rgba(0,0,0,0.3);
}
.new-folder-control {
	font-size: 14px;
	border: solid 1px #E0E0E0;
	border-radius: 2px;
	padding-left: 3px;
	padding-right: 3px;
	max-width: 100%;
}

.natural-size-info-box {
	display: block;
	padding: 5px 10px;
	font-size: 13px;
	background-color: rgba(255, 255, 255, 0.5);
	color: #222;
	position: absolute;
	top: 0;
	right: 0;
	width: auto;
	line-height: 1;
	border-radius: 0 0 3px 0;
	z-index: 2;
}

.cropper-form {
	width: 100%;

	img {
		width: 100%;
		max-width: 100%;
	}
}
.cropper-container {
	width: 100% !important;
}
.cropped-image-preview {
	position: absolute;
	z-index: 10;
	background-color: rgba(0, 0, 0, 0.75);
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow: auto;
	display: none;

	&.displayed {
		display: flex;
		flex-flow: column nowrap;
		align-content: flex-start;
		justify-content: flex-start;

		canvas {
			flex: 0 0 auto;
			align-self: center;
		}
	}

	canvas.centered {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate3d(-50%, -50%, 0);
	}
}
.cropper-crop-box {
	.size-info-box {
		display: none;
		padding: 5px 10px;
		font-size: 13px;
		background-color: rgba(255, 255, 255, 0.5);
		color: #222;
		position: absolute;
		bottom: 0;
		left: 0;
		width: auto;
		line-height: 1.2;
		border-radius: 0 3px 0 0;
	}
	&:hover {
		.size-info-box {
			display: inline-block;
		}
	}
}

@media screen and (min-width: 30em) {
	.directory-list-area {
		.inner-wrapper > ol {
			li {
				flex: 0 0 $FILE-ICON-W-MD;
				width: $FILE-ICON-W-MD;
			}
		}

		.dir-wrapper i.item-type {
			height: $DIR-ICON-SIZE-MD;
			font-size: $DIR-ICON-SIZE-MD;
			line-height: $DIR-ICON-SIZE-MD;
		}
	}
}
@media screen and (min-width: 48em) {
	.directory-list-area {
		.inner-wrapper > ol {
			li {
				flex: 0 0 $FILE-ICON-W-XMD;
				width: $FILE-ICON-W-XMD;
			}
		}

		.dir-wrapper i.item-type {
			height: $DIR-ICON-SIZE-XMD;
			font-size: $DIR-ICON-SIZE-XMD;
			line-height: $DIR-ICON-SIZE-XMD;
		}
	}
	cmp-file-uploader {
		min-height: 550px;
	}
}
@media screen and (min-width: 64em) {
	.directory-list-area {
		.inner-wrapper > ol {
			li {
				flex: 0 0 $FILE-ICON-W-LG;
				width: $FILE-ICON-W-LG
			}
		}

		.dir-wrapper i.item-type {
			height: $DIR-ICON-SIZE-LG;
			font-size: $DIR-ICON-SIZE-LG;
			line-height: $DIR-ICON-SIZE-LG;
		}
		.dir-wrapper .directory-name {
			display: block;
		}
	}
	cmp-file-uploader {
		min-height: 650px;
	}
}
@media screen and (min-width: 73.125em) {
	.directory-list-area {
		.inner-wrapper > ol {
			li {
				flex: 0 0 $FILE-ICON-W-XLG;
				width: $FILE-ICON-W-XLG;
			}
		}
	}
	cmp-file-uploader {
		min-height: 700px;
	}
}