@import "../includes";

.form-group .form-control.colorpicker-input,
.form-control.colorpicker-input {
	width: auto;
}
.btn[colorpicker] {
	padding-left: 13px;
	padding-right: 5px;

	.color-fill-icon {
		display: inline-block;
		vertical-align: middle;
		width: 20px;
		height: 20px;
		border: solid 1px rgba(0, 0, 0, 0.3);
	}

	i {
		color: rgba(0, 0, 0, 0.3);
	}

	&:hover {
		i {
			color: rgba(0, 0, 0, .7);
		}
	}
}
// .colorpicker {
// 	box-shadow: bottom-shadow(3);
// 	padding: 6px;
// }
// .colorpicker-right:before {
//     right: auto;
//     left: -14px;
//     border-bottom-color: transparent;
//     border-right-color: #ccc;
//     bottom: 12px;
//     top: auto;
//     border-top: solid 7px transparent;
// }
// .colorpicker-right:after {
//     right: 7px;
//     left: auto;
//     right: auto;
//     left: -12px;
//     top: auto;
//     bottom: 13px;
//     border-top: solid 6px transparent;
//     border-bottom-color: transparent;
//     border-right-color: #fff;
// }

.colorpicker {
	z-index: 1070;
	background: #fff;
	box-shadow: bottom-shadow(3);
	border-radius: 2px;
	border: solid 1px #dedede;

	.colorpicker_hue {
		background-image: url(/assets/img/colorpicker/hue_bar.jpg);
		background-repeat: no-repeat;
		background-size: auto 150px;
		background-position: center;
	}

	.colorpicker_field, .colorpicker_hex {
		background: none;

		&:before {
			content: "#";
			display: block;
			font-size: 13px;
			height: 20px;
			line-height: 20px;
			float: left;
			text-indent: 2px;
		}

		input {
			right: 3px;
			color: #222;
			font-size: 13px;
			height: 20px;
			border: none;
			border-bottom: solid 1px #ccc;
			margin-top: -4px;
			width: 70%;

			& + span {
				display: none;
			}

			&:focus {
				border-bottom-color: #6b66ff;
			}
		}

		&.colorpicker_rgb_r:before {
			content: "R";
		}
		&.colorpicker_rgb_g:before {
			content: "G";
		}
		&.colorpicker_rgb_b:before {
			content: "B";
		}
		&.colorpicker_hsb_h:before {
			content: "H";
		}
		&.colorpicker_hsb_s:before {
			content: "S";
		}
		&.colorpicker_hsb_b:before {
			content: "B";
		}
	}

	.colorpicker_submit {
		background: none;

		&:before {
			@include material-icon-prop();
			content: "done";
			width: 22px;
			height: 22px;
			text-align: center;
			line-height: 22px;
			color: rgba(0,0,0,0.4);
		}

		&:hover {
			cursor: pointer;

			&:before {
				color: rgba(0,0,0,1);
			}
		}
	}

	.colorpicker_current_color,
	.colorpicker_new_color {
		border: solid 1px #000;
	}
}
.colorpicker-box {
	input.form-control {
		display: inline-block;
	}

	.color-box {
		display: none;
		height: 24px;
		width: 24px;
		border: solid 1px #333;
		vertical-align: middle;
		margin-right: 5px;
	}
}
.colorpicker-wrapper {
	.input-wrapper input:not([type="file"]):not([type="checkbox"]):not([type="radio"]).form-control[readonly] {
		color: #333;
		cursor: pointer;
	}

	&.has-color {
		.input-wrapper:before {
			content: "#";
			color: #333;
			margin-right: 1px;
		}
		.color-box {
			display: inline-block;
		}
	}
}