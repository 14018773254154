@import "../_includes";

.sidenav {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	z-index: 4;
	width: $SIDENAV-W;
	opacity: 0;
	transform: translateX(calc(-100% - 10px));
	transition: all 400ms $EASE-OUT;
	overflow: hidden;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;

	&.opened {
		opacity: 1;
		transform: translateX(0);

		& + .sidenav-overlay {
			display: block;
			animation: OPC-zero-to-one 300ms $EASE-OUT .05s forwards;
		}
	}

	.sidenav-cms-logo-box {
		margin-top: 100px;
		opacity: .6;
		padding-bottom: 15px;
		padding-left: 15px;

		img {
			height: $HDR-H - 30px;
		}
	}
}
.sidenav-overlay {
	position: fixed;
	display: none;
	z-index: 3;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	opacity: 0;
}
.menu-wrapper {
	overflow: hidden;
	
	.parent-menu-item:not(.single-item) + ul {
		height: 0;
		overflow: hidden;
		opacity: 0;
	}

	.parent-menu-item:not(.single-item) {
		&.ready + ul {
			transition: all 400ms $EASE-OUT;
			opacity: 1;
		}
	}
}
.sidenav-error-block {
	padding: 15px;
	font-size: 1rem;
	color: #9E9E9E;
	line-height: 1.3;
}

@media screen and (min-width: 64em) {
	.sidenav {
		.sidenav-cms-logo-box {
			position: absolute;
			bottom: 15px;
			left: 15px;
			padding: 0;
		}
	}
}
@media screen and (min-width: 73.125em) {
	.sidenav {
		z-index: 3;
		top: $HDR-H;
	}
	.menu-wrapper {
		padding-top: 10px;
	}
	.sidenav, .sidenav.opened {
		opacity: 1;
		transform: translateX(0);

		& + .sidenav-overlay {
			display: none;
		}
	}
}