@import "../includes";

@mixin default-toggle-element() {
	position: relative;
	cursor: pointer;
	overflow: visible;
	display: table;
	height: auto;
	width: 100%;
	margin-bottom: 16px;

	.md-toggle-element {
		position: absolute;
		cursor: pointer;
		pointer-events: all;
		opacity: 0;
		width: 100%;
		height: 100%;
		z-index: 2;
		left: 0px;
		box-sizing: border-box;
		padding: 0px;
		margin: 0px;

		&:focus + .effect-wrapper {
			.box-style-wrapper .toggle-slide {
				box-shadow: bottom-shadow(2);
			}
		}
	}

	.effect-wrapper {
		position: relative;
		z-index: 1;
		display: flex;
		width: 100%;
		height: 100%;

		.box-style-wrapper {
			transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
			float: left;
			position: relative;
			display: block;
			flex-shrink: 0;
			width: 36px;
			margin-right: 0px;
			margin-left: 0;
			padding: 4px 0px 6px 2px;

			.toggle-bar {
				transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
				width: 100%;
				height: 14px;
				border-radius: 30px;
				background-color: rgb(189, 189, 189);
			}
			.toggle-slide {
				color: rgba(0, 0, 0, 0.870588);
				background-color: rgb(245, 245, 245);
				transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
				box-sizing: border-box;
				font-family: $COMMON-FONT;
				-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
				box-shadow: rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px;
				border-radius: 50%;
				position: absolute;
				top: 1px;
				left: 0px;
				width: 20px;
				height: 20px;
				line-height: 24px;

				.ripper-helper {
					.ripper-block {
						height: 200%;
						width: 200%;
						position: absolute;
						top: -10px;
						left: -10px;
						overflow: hidden;
						color: rgba(0, 0, 0, 0.870588);
					}
				}
				.slide-box {
					position: absolute;
					overflow: hidden;
					height: 100%;
					width: 100%;
					top: 0px;
					left: 0px;
				}
			}
		}

		.toggle-element-label {
			float: left;
			position: relative;
			display: block;
			width: calc(100% - 46px);
			line-height: 24px;
			color: rgba(0, 0, 0, 0.870588);
			font-family: $COMMON-FONT;
			cursor: initial;
			font-weight: 400;
			transition: color 450ms $EASE-OUT;
			user-select: none;
			white-space: nowrap;
		}
	}

	.md-toggle-element:checked {
		& + .effect-wrapper {
			.box-style-wrapper {
				.toggle-bar {
					background-color: rgba(0, 188, 212, 0.498039);
				}
				.toggle-slide {
					color: rgba(0, 0, 0, 0.870588);
					background-color: rgb(0, 188, 212);
					box-shadow: rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px;
					left: 100%;
					margin-left: -19px;

					.ripper-helper {
						.ripper-block {
							color: rgb(0, 188, 212);
						}
					}
				}
			}
		}


	}

	.md-toggle-element:disabled {
		& + .effect-wrapper {
			.box-style-wrapper {
				.toggle-bar {
					background-color: rgb(189, 189, 189);
					cursor: not-allowed;
				}
				.toggle-slide {
					color: rgba(0, 0, 0, 0.870588);
					background-color: rgb(224, 224, 224);
					box-shadow: rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px;
					cursor: not-allowed;

					.ripper-helper {
						.ripper-block {
							display: none;
						}
					}
					.slide-box {
						display: none;
					}
				}
			}

			.toggle-element-label {
				color: rgba(0, 0, 0, 0.298039);
			}
		}
	}

	&.label-right {
		.effect-wrapper {
			.box-style-wrapper {
				margin-right: 8px;
			}
		}
	}

	&.inline-block {
		width: auto;
		display: inline-block;
	}
}

.md-toggle-wrapper {
	@include default-toggle-element();
}

.inline-toggle {
	display: inline-block;
	width: auto;
}