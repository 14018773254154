@import "../includes";

.ui-datepicker {
	z-index: 1200 !important;
	background-color: #fff;
	box-shadow: bottom-shadow(2);
	border-radius: 2px;
	padding: 10px;
	
	.ui-datepicker-header {
		display: flex;
		flex-flow: row nowrap;
		align-items: stretch;
		justify-content: flex-start;
		margin-bottom: 10px;
	}
	.ui-datepicker-prev {
		order: 1;
		flex: 0 0 40px;

		.ui-icon:before {
			content: "keyboard_arrow_left";
		}
	}
	.ui-datepicker-next {
		order: 3;
		flex: 0 0 40px;

		.ui-icon:before {
			content: "keyboard_arrow_right";
		}
	}
	.ui-datepicker-title {
		order: 2;
		flex: 1 0 auto;
		text-align: center;
		align-items: center;
		line-height: 40px;
	}

	.ui-datepicker-prev, .ui-datepicker-next {
		@include clearfix();

		.ui-icon {
			display: block;
			float: left;
			overflow: hidden;
			text-align: center;
			width: 40px;
			height: 40px;
			line-height: 40px;
			border-radius: 50%;

			&:before {
				@include material-icon-prop();
				display: block;
				text-indent: 0;
				float: left;
				width: 40px;
				height: 40px;
				line-height: 40px;
			}

			&:hover {
				background-color: rgba(0,0,0,0.05);
			}
		}
	}

	.ui-datepicker-calendar {
		border-collapse: collapse;

		thead {
			th {
				padding: 5px 10px;
    			font-weight: 500;
			}
		}
		tbody td {
			a {
				display: block;
				padding: 5px 10px;
				text-align: right;				

				&.ui-state-default:not(.ui-state-active).ui-state-hover {
					color: #222;
					background-color: #EEE;
				}

				&.ui-state-active {
					background-color: #448AFF;
					color: #fff;
					cursor: default;
				}
				&.ui-state-highlight {
					position: relative;

					&:after {
						content: "";
						position: absolute;
						width: 0;
						height: 0;
						bottom: 0;
						right: 0;
						border-top: solid 5px transparent;
						border-right: solid 5px #448AFF;
						border-bottom: solid 5px #448AFF;
						border-left: solid 5px transparent;
					}
				}
			}
		}
	}
}