.sparkline {
  stroke: #ADB0B6;
  stroke-width: 1;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round; }
  .sparkline .line {
    stroke: url(#sparkline-line-gradient);
    stroke-width: 4; }
  .sparkline .sparkline-circle {
    fill: #ff584c;
    stroke-width: 0;
    display: none; }
  .sparkline .sparkline-area {
    fill: url(#sparkline-area-gradient);
    stroke: none; }
