@import "../includes";

header {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	height: $HDR-H;
	z-index: 3;
}
.app-menu-toggle-wrapper {
	display: block;
	position: absolute;
	top: 0;
	left: 0;

	.btn-sidenav-toggle {
		border: none;
		padding: 0;
		margin: 0;
		float: left;

		i {
			float: left;
			width: $HDR-H;
			height: $HDR-H;
			text-align: center;
			line-height: $HDR-H;
			border-radius: 50%;
		}
	}
}
.app-menu-wrapper {
	position: absolute;
	top: 0;
	right: 0;
	height: $HDR-H;

	.dropdown {
		float: left;
		width: $HDR-H;
		height: $HDR-H;
	}

	.username-label {
		display: none;
		height: $HDR-H - 4px;
		line-height: $HDR-H - 4px;
		vertical-align: top;
	}

	.app-menu-btn {
		display: block;
		cursor: pointer;

		i {
			float: left;
			width: $HDR-H - 14px;
			height: $HDR-H - 14px;
			text-align: center;
			line-height: $HDR-H - 14px;
			border-radius: 50%;
			margin: 7px;
		}

		&:after {
			display: none;
		}
	}
	.dropdown-menu {
		left: auto;
		right: 0;
		top: $HDR-H - 2px;

		&.search-wrapper {
			position: fixed;
			padding: 10px;
			left: 0;
			max-height: 400px;
			overflow: hidden;
			overflow-y: auto;
		}
	}
}
.search-wrapper {
	.search-loader {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: rgba(255, 255, 255,.5);

		[input-loader] {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate3d(-50%, -50%, 0);
		}
	}
}
.app-search-result {
	li {
		padding: 7px 10px;

		.item-name {
			display: block;

			&:hover {
				color: #2979FF;
				cursor: pointer;
			}
		}

		& + li {
			border-top: solid 1px rgba(0, 0, 0, .05);
		}
	}
}
.app-search-not-found {
	margin-top: 10px;
	color: rgba(0,0,0,0.4);

	i {
		vertical-align: middle;
		transform: translateY(-1px);
		margin-right: 6px;
	}
}
.app-site-selector {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	width: 30%;
	max-width: 220px;
	min-width: 160px;

	.dropdown {
		width: 100%;
	}

	.site-selector-button {
		width: 100%;
		height: $HDR-H;
		text-align: center;
		line-height: $HDR-H;

		i {
			display: none;
		}
	}

	.dropdown-menu {
		left: 50%;
		// width: 160px;
		// margin-left: -80px;
		margin-left: -150px;
		width: 300px;
		height: 300px;
		overflow-y: auto;
	}
	.dropdown-item {
		padding: .5rem 1rem;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}
.brand-logo-box-desktop {
	display: none;
}
.search-item-tip {
	color: #888;
}


.app-top-bar {
	position: fixed;
	z-index: 2;
	top: $HDR-H;
	height: auto;
	left: 0;
	right: 0;

	.page-title {
		display: inline-block;
		height: $TOP-BAR-H;
		font-size: 1.6rem;
		font-weight: 500;
		padding: 0 15px;
		line-height: $TOP-BAR-H;
		margin: 0;
	}

	.top-bar-button-wrapper {
		display: inline-block;
		vertical-align: top;
		padding-top: 8px;

		button i {
			margin-right: 12px;
		}
		button i + span {
			display: none;
		}
		button + button {
			margin-left: 3px;
		}

		&.float-right {
			float: right;
			margin-right: 15px;
		}
	}
}

@media screen and (min-width: 48em) {
	.app-site-selector {
		max-width: 400px;

		.site-selector-button i {
			display: inline-block;
		}
	}

	.app-top-bar {
		.top-bar-button-wrapper {
			button i {
				margin-right: 0;
			}
			button i + span {
				display: inline-block;
			}
		}
	}

	.app-menu-wrapper {
		.dropdown-menu {
			&.search-wrapper {
				position: fixed;
				padding: 10px;
				left: auto;
				width: 600px;
				right: 15px;
			}
		}
	}
}

@media screen and (min-width: 73.125em) {
	.app-menu-wrapper {
		.username-label {
			display: inline-block;
			transform: translateY(2px);
		}
	}

	.app-menu-wrapper {
		right: 15px;
	}

	header {
		z-index: 4;
	}

	.brand-logo-box-desktop {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		padding-left: 15px;
		padding-top: 12px;

		img {
			height: $HDR-H - 24px;
		}
	}

	.app-menu-toggle-wrapper {
		display: none;
	}

	.app-site-selector {
		max-width: 600px;
	}

	.app-top-bar {
		left: $SIDENAV-W;
	}
}
