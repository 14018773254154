@import "../includes";

$DASHBOARD-ITEM-H: 160px;
.dashboard-wrapper {
	position: relative;
	padding: 20px;
}
.dashboard-list {
	@include clearfix();

	.item {
		float: left;
		width: 100%;
		padding: 5px;

		&.height-one-half .inner-block {
			height: $DASHBOARD-ITEM-H * 1.5;
		}
		&.height-double .inner-block {
			height: $DASHBOARD-ITEM-H * 2;
		}
		&.height-dbl-half .inner-block {
			height: $DASHBOARD-ITEM-H * 2.5;
		}
		&.height-triple .inner-block {
			height: $DASHBOARD-ITEM-H * 3;
		}
		&.line-chart .inner-block {
			height: auto;

			.chart-area {
				padding: 60px 20px 20px;
			}
		}
		.chart-area {
			position: absolute;
			top: 60px;
			padding: 0;
			right: 20px;
			bottom: 15px;
			left: 20px;
		}
		.empty-data {
       		display: flex;
            align-items: center;
			width: 100%;
			margin-left: auto;
			margin-right: auto;
			height: 300px;
            justify-content: center;
			font-size: 1.5rem;
			font-weight: 600;
		}
		.recent-activities {
			height: 350px;
			overflow: hidden;
			overflow-y: auto;
			padding-right: 15px;
			padding-left: 15px;
			width: 100%;
			font-size: 1rem;
			font-weight: 400;
			color: #000;

			li + li {
				margin-top: 10px;
			}

			li {
				padding: 10px;
    			background-color: #fff;
    			border-radius: 2px;
				box-shadow: 0 1.5px 4px rgba(0,0,0,0.24);
				

				.status-title {
					word-wrap: break-word;
				}

				.status-description {
					font-style: italic;
					font-size: 0.95rem;
					border-top: 1px solid #bbb;
					margin-top: 5px;

					small {
						display: inline-block;
					}
				}
			}
		}
	}

	.inner-block {
		@include clearfix();
		position: relative;
		background-color: #fff;
		border-radius: 2px;
		box-shadow: bottom-shadow(1);
		width: 100%;
	}

	.dashboard-title {
		display: block;
		width: 100%;
		float: left;
		margin: 0;
		padding: 15px;
		font-size: 1rem;
		font-weight: 400;
		line-height: 1;
	}

	.dashboard-number-value {
		float: right;
		font-size: 4.7rem;
		font-weight: 500;
		line-height: 1;
		letter-spacing: .05rem;
		padding-left: 30px;
		padding-right: 30px;
		padding-bottom: 20px;
		margin-top: 20px;
	}
	
}
.no-dashboard-info {
	position: absolute;
	top: 50%;
	left: 30px;
	right: 30px;
	transform: translate3d(0, -50%, 0);
	max-width: 90%;
	opacity: .4;

	.msg-icon {
		display: block;
		width: 200px;
		height: 200px;
		line-height: 200px;
		font-size: 180px;
		margin: 0 auto;
		opacity: .3;
	}
	.message {
		display: block;
		font-size: 1.4rem;
		line-height: 1.4;
		text-align: center;
	}
}

@media screen and (min-width: 768px) {
	.dashboard-list .item {
		width: 50%;
	}

	.dashboard-list .item.width-double {
		width: 100%;
	}
}

@media screen and (min-width: 992px) {
	.dashboard-wrapper {
		max-width: 900px;
		margin: 0 auto;
	}
	.dashboard-list .item {
		width: 33.333333%;

		.inner-block {
			height: $DASHBOARD-ITEM-H;
		}

		&.width-half {
			width: 50%;
		}
		&.width-double {
			width: 66.666667%;
		}
		&.width-triple {
			width: 100%;
		}

		&.height-double + .item {
			margin-top: -$DASHBOARD-ITEM-H;
		}

		canvas {
			margin: 0 auto;
		}

		.line canvas {
			max-width: 95%;
		}

		.doughnut-chart-percentage {
			position: absolute;
			top: calc(50% - 15px);
			left: 50%;
			font-size: 4rem;
			font-weight: 400;
			transform: translate3d(-50%, -50%, 0);
			color: #424242;

			&:after {
				content: "%";
			}
		}
	}
	.no-dashboard-info {
		left: 50px;
		right: 50px;
	}
}

@media screen and (min-width: 1200px) {
	.dashboard-wrapper {
		padding: 70px 30px 30px;
	}
	.dashboard-wrapper {
		max-width: 1100px;
	}
}