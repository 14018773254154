@import "../_includes";

.tab-bar-wrapper {
	position: relative;
	display: flex;
	flex-flow: row nowrap;
	margin-bottom: 20px;

	.tab-button {
		flex: 1 0 auto;
		height: 48px;
		line-height: 48px;
		text-align: center;
		font-weight: 400;
		border-bottom: solid 2px transparent;
		color: #BDBDBD;
		transition: all 300ms $EASE-OUT;
		cursor: pointer;

		&:hover {
			background-color: #F5F5F5;
		}

		& + .tab-button {
			border-left: solid 1px #ccc;
		}
		&.active {
			color: #212121;
			border-bottom-color: #448AFF;
		}
	}
}