@import "../_includes";

// Scrollbar style only for latest versions of Opera, Google Chrome, Safari, Android, and iOS.
::-webkit-scrollbar {
	width: 10px;
}
::-webkit-scrollbar-track {
	background: #FFF;
	-webkit-box-shadow: inset 1px 1px 2px rgba(0,0,0,0.1);
}
::-webkit-scrollbar-thumb {
	background: #CCC;
	-webkit-box-shadow: inset 1px 1px 2px rgba(0,0,0,0.2);
}
::-webkit-scrollbar-thumb:hover {
	background: #AAA;
}
::-webkit-scrollbar-thumb:active {
	background: #888;
	-webkit-box-shadow: inset 1px 1px 2px rgba(0,0,0,0.3);
}



html, body {
	height: 100%;
	overflow-x: hidden;
}
main {
	position: fixed;
	top: $HDR-H;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: hidden;
	overflow-y: auto;
	padding-top: 0;
	min-height: initial;
	
	&.after-top-bar {
		top: $HDR-H + $TOP-BAR-H;
	}
	&.after-breadcrumb {
		top: $HDR-H + $TOP-BAR-H + $BRDCRMB-H;
	}
	&.navigating {
		opacity: 0;
	}
}
[cmp-dashboard], .cmp-container {
	position: relative;
	padding: 15px;

	&.full-expand {
		position: absolute;
		padding: 0;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		 .component-wrapper {
			position: absolute;
			padding: 30px;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			overflow: hidden;
			overflow-y: auto;
		 }
	}
}

.hidden {
	display: none;
	visibility: hidden;
}
.app-error-wrapper {
	padding: 30px;
}
.component-wrapper {
	position: relative;
	display: block;
	min-height: 120px;
	padding: 15px;

	.component-busy {
		position: fixed;
		z-index: 100;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: rgba(255, 255, 255, .2);

		&.white-bg {
			background-color: rgba(255, 255, 255, .6);
		}

		.spinner {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate3d(-50%, -50%, 0);
			margin: 0;
		}
	}

	&.busy {
		opacity: .5;
	}
}


// util
.block {
	display: block;
}
.no-mgn-bottom {
	margin-bottom: 0;
}
.mgn-top-sm {
	margin-top: 5px;
}
.mgn-bottom-med {
	margin-bottom: 15px;
}
.mgn-bottom-sm {
	margin-bottom: 5px;
}
@media screen and (max-width: 1024px) {
	.router-wrapper {
		-webkit-overflow-scrolling: touch;
	}
}
@media screen and (min-width: 73.125em) {
	main {
		position: fixed;
		top: $HDR-H;
		left: $SIDENAV-W;
		right: 0;
		bottom: 0;
		overflow: hidden;
		overflow-y: auto;
		padding-top: 0;
    	min-height: initial;

		&.after-top-bar {
			padding-top: 0;
			top: $HDR-H + $TOP-BAR-H;
		}
		&.after-breadcrumb {
			padding-top: 0;
			top: $HDR-H + $TOP-BAR-H + $BRDCRMB-H;
		}
	}
	.component-wrapper {
		.component-busy {
			left: $SIDENAV-W;
		}
	}
}