@import "../includes";

body {
	background-color: #fff;
}
.login-bg-layer {
	background-color: #80CBC4;
}
.login-panel {
	background-color: #fff;
	padding: 15px;
	box-shadow: bottom-shadow(3);
	border-radius: 2px;
	overflow: hidden;

	.title {		
		border-bottom: solid 2px rgba(0,0,0,.1);
	}
}
.form-busy-layer {
	background-color: rgba(255, 255, 255, 0.6);
}

main {
	background-color: #fff;

	&.grayed {
		background-color: #E0E0E0;
	}
}

//Error
.app-error-wrapper {
	color: #BDBDBD;

	i {
		font-size: 2.6rem;
		margin-right: 6px;
		vertical-align: middle;
		transform: translateY(-10%);
	}

	.error-message {
		font-size: 2rem;
		font-weight: 400;
	}
}

//Header
header {
	background-color: #fff;
	box-shadow: bottom-shadow(2);
}
.sidenav {
	background-color: #F5F5F5;
	box-shadow: right-shadow(1.5);
}
.sidenav-overlay {
	background-color: rgba(0,0,0,.15);
}
.app-menu-wrapper {
	.app-menu-btn {
		float: left;
		border: none;
		background-color: #fff;
		padding: 0;
		margin: 0;
		line-height: 100%;

		i {
			transition: background-color 500ms $EASE-OUT;
			color: rgba(0,0,0,0.2);

			&.darken {
				color: rgba(0,0,0,0.5);
			}
		}
		i:hover {
			color: rgba(0,0,0,.8);
		}
	}
	.dropdown.show {
		i {
			color: rgba(0,0,0,.8);

			&.lighten {
				color: rgba(0, 0, 0, .4);
			}
		}
	}
}
.btn-sidenav-toggle {
	background-color: #fff;
	transition: background-color 500ms $EASE-OUT;
	cursor: pointer;
	user-select: none;

	i {
		color: #757575;
	}

	&:active {
		background-color: #E0E0E0;
	}

	&:hover {
		background-color: #E0E0E0;

		i {
			color: #212121;
		}
	}
}

//Top Bar
.app-top-bar {
	background-color: #F5F5F5;
	box-shadow: bottom-shadow(1);
}

// Sidebar
$NAV-ITEM-H: 44px;
.menu-wrapper {
	background-color: #F5F5F5;

	.parent-menu-item, .menu-item {
		position: relative;
		display: block;
		padding-left: 70px;
		padding-right: 15px;
		color: #000;
		background-color: #fff;

		&:hover, &:active, &:focus {
			text-decoration: none;
		}

		&:not(.height-auto) {
			height: $NAV-ITEM-H;
			line-height: $NAV-ITEM-H;
		}

		&.padd-normal {
			padding-left: 15px;
		}

		&.no-click {
			cursor: default;
		}
	}

	.parent-menu-item {
		color: #424242;
		background-color: #F5F5F5;
		font-weight: 500;
		padding-right: 40px;
		cursor: pointer;
		transition: background-color 500ms $EASE-OUT;
		will-change: background-color;

		h1, h2, h3, h4, h5, h6 {
			margin-bottom: 5px;
		}

		&:not(.plain-box) {
			box-shadow: bottom-shadow(1);
		}

		.collapse-ico {
			position: absolute;
			transform-origin: 50% 50%;
			width: 24px;
			height: 8px;
			text-align: center;
			line-height: 8px;
			top: ($NAV-ITEM-H - 8px) / 2;
			right: ($NAV-ITEM-H - 24px) / 2;
			transition: transform 400ms $EASE-OUT;
		}

		&:not(.expanded):not(.no-click):hover {
			background-color: #E0E0E0;
		}

		&.expanded {
			// background-color: #E0E0E0;

			.collapse-ico {
				transform: rotate(90deg);
			}
		}

		&.no-click {
			cursor: default;
		}
	}

	.ico {
		position: absolute;
		z-index: 2;
		top: 0;
		left: 10px;
		width: $NAV-ITEM-H;
		height: $NAV-ITEM-H;
		text-align: center;
		line-height: $NAV-ITEM-H;
		vertical-align: top;
		color: rgb(117, 117, 117);
	}

	hr {
		margin: 0;
		height: 1px;
		border: none;
		background-color: rgb(224, 224, 224);
	}

	.menu-item {
		transition: background-color 500ms $EASE-OUT;
		will-change: background-color;

		&:hover {
			cursor: pointer;
			background-color: #EEEEEE;
		}

		&.active {
			background-color: #2979FF;
			color: #fff;

			.ico {
				color: #fff;
			}
		}
	}
}
.switch-app-mode-button {
	font-size: 14px;
	display: block;
	margin-bottom: 20px;
	cursor: pointer;	

	span {
		color: #448AFF;
		font-weight: 400;
	}

	&:hover {
		span {
			color: #2962FF;
			text-decoration: none;
		}		
	}
}

//Dialog
.dialog-close-btn {
	i {
		transition: background-color 500ms $EASE-OUT;
	}
	&:hover {
		i {
			background-color: rgb(255, 64, 129);
			color: #fff;
		}
	}
}

//Form
.form-group {
	label:first-child {
		display: block;
		font-size: 13px;
		margin-bottom: 3px;
	}

	.form-control {
		padding: .5rem .5rem .5rem 0;

		& + .icon-for-input {
			display: none;
			vertical-align: bottom;
			@include clearfix();

			i {
				height: 36px;
				line-height: 36px;
				font-size: 28px;
				float: left;
			}

			&.icon-success {
				i {
					color: #4CAF50;
				}
			}
			&.icon-error {
				i {
					color: #E53935;
				}
			}
		}

		&.icon-input {
			display: inline-block;
			width: calc(100% - 40px);

			& + .icon-for-input {
				display: inline-block;
				width: 36px;
			}
		}
	}

	&.has-danger {
		small {
			color: #F44336;
		}
	}
}

.clickable-icon {
	cursor: pointer;
}

//Misc
.tip-text {
	margin-top: 5px;
	line-height: 1;
	
	&.has-icon {
		padding-left: 30px;

		i {
			margin-left: -30px;
		}
	}

	&.hoverable {
		opacity: .4;

		&:hover {
			opacity: 1;
		}	
	}
}
.submission-no-data-text {
	font-size: 14px;
	color: rgba(0,0,0,.5);
}
.submission-detail-wrapper {
	margin-top: 20px;
}
.submission-key {
	display: block;
	font-size: 13px;
	color: #222;
	margin-bottom: 7px;
}
.submission-value {
	display: block;
	font-size: 1rem;
}

@media screen and (min-width: 73.125em) {
	header {
		box-shadow: bottom-shadow(2);
	}
	.sidenav {
		box-shadow: right-shadow(1);
	}
}