.line-chart .topic .line {
  fill: none;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round; }

.line-chart .x.axis path,
.line-chart .y.axis path {
  display: none; }

.line-chart .month-axis path {
  display: none; }

.line-chart .masking-rectangle {
  fill: #ffffff; }
