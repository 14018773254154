@import "../includes";

.map-wrapper {
	position: relative;

	.map-search-box {
		position: absolute;
		z-index: 2;
		box-shadow: bottom-shadow(2);
		border-radius: 2px;
		background-color: #fff;
		padding: 10px;
		top: 10px;
		right: 10px;
		left: 10px;		
	}

	.map-elem {
		position: relative;
		height: 400px;
		background-color: #ccc;
	}	

	.search-place-input {
		width: calc(100% - 20px);
		padding-left: 10px !important;
		padding-right: 10px !important;
		top: 10px !important;
		left: 10px !important;
	}
}
.map-represent-icon {
	width: 50px;
	height: 50px;
	line-height: 50px;
	text-align: center;
	font-size: 40px;
	color: rgba(0,0,0,0.4);
}

@media screen and (min-width: 768px) {
	.map-wrapper {
		.map-search-box {
			width: 300px;
			right: auto;
		}

		.search-place-input {
			width: 300px;
		}
	}
}

@media screen and (min-width: 992px) {
	.map-wrapper {
		.map-search-box {
			width: 500px;
		}
		.search-place-input {
			width: 300px;
		}
	}
}