$COMMON-FONT: 'Roboto', sans-serif, 'Arial';

$EASE-OUT-ANIM: cubic-bezier(0.075, 0.820, 0.165, 1.000);
$EASE-OUT: cubic-bezier(0.23, 1, 0.32, 1);
$EASE-IN-OUT: cubic-bezier(0.445, 0.05, 0.55, 0.95);
$XSML-SPC: 3px;
$SML-SPC: 5px;
$MED-SPC: 10px;
$XMED-SPC: 15px;
$LG-SPC: 20px;
$XLG-SPC: 30px;
$XXLG-SPC: 40px;

$ANIM-TIME-VFAST: .1s;
$ANIM-TIME-FAST: .2s;
$ANIM-TIME-BFAST: .35s;
$ANIM-TIME-NRM: .5s;
$ANIM-TIME-BSLOW: .75s;
$ANIM-TIME-SLOW: 1s;
$ANIM-TIME-VSLOW: 1.5s;

$HDR-H: 50px;
$TOP-BAR-H: 50px;
$SIDENAV-W: 280px;
$BRDCRMB-H: 36px;

$DLG-ANIM-OFFSET-Y: -80px;
$COMMON-FONT: 'Roboto', sans-serif;
$BASE-FNT-WEIGHT: 400;
$HINT-TEXT-FNT-WEIGHT: 400;