@import "../includes";


// Loader
@keyframes rotate {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

@keyframes fillunfill {
	from {
		stroke-dashoffset: 58.8
		/* 2*RADIUS*PI * ARCSIZE/360 - 0.1 */
		/* 0.1 a bit of a magic constant here */
	}
	50% {
		stroke-dashoffset: 0;
	}
	to {
		stroke-dashoffset: -58.4
		/* -(2*RADIUS*PI * ARCSIZE/360 - 0.5) */
		/* 0.5 a bit of a magic constant here */
	}
}
  
@keyframes rot {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(-360deg);
	}
}

@keyframes colors {
0% {
	stroke: #754fa0;
}
20% {
	stroke: #09b7bf;
}
40% {
	stroke: #90d36b;
}
60% {
	stroke: #f2d40d;
}
80% {
	stroke: #fcb12b;
}
100% {
	stroke: #ed1b72;
}
}

app-loader {
	display: none;
	opacity: 0;
	z-index: 2000;
	position: fixed;

	&.displayed {
		display: block;
		animation: OPC-zero-to-one $EASE-OUT-ANIM $ANIM-TIME-BFAST .05s forwards;
	}
	&.closing {
		display: block;
		opacity: 1;
		animation: OPC-one-to-zero $EASE-OUT-ANIM $ANIM-TIME-BFAST forwards;
	}
}
.app-loader {
	position: fixed;
	display: block;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(255,255,255,.7);

	.loader-box {
		position: absolute;
		display: block;
		top: 50%;
		left: 50%;
		transform: translate3d(-50%,-50%,0);

		.loading-text {
			font-size: 16px;
			display: block;
			margin: 0 auto;
			text-align: center;
		}

		//Spinner
		/*
		* Constants:
		*      RADIUS      = 12.5
		*      STROKEWIDTH = 3
		*      ARCSIZE     = 270 degrees (amount of circle the arc takes up)
		*      ARCTIME     = 1333ms (time it takes to expand and contract arc)
		*      ARCSTARTROT = 216 degrees (how much the start location of the arc
		*                                should rotate each time, 216 gives us a
		*                                5 pointed star shape (it's 360/5 * 2).
		*                                For a 7 pointed star, we might do
		*                                360/7 * 3 = 154.286)
		*
		*      SHRINK_TIME = 400ms
		*/
		
		@include spinner-prop();
	}
}

.form-busy-layer, .dialog-busy-wrapper, .image-block-busy {
	@include spinner-prop();

	.spinner {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate3d(-50%, -50%, 0);
		margin: 0;

		&.fix-to-top {
			top: 50px;
			transform: translate3d(-50%, -50%, 0);
		}
	}
	.loading-text {
		font-size: 16px;
		display: block;
		margin: 0 auto;
		text-align: center;
		font-weight: 500;
	}
}
[input-loader] {
	@include spinner-prop(24px, rgba(255,255,255,0), 0, none, 0);
}