@import "../includes";

//Ripple
.ripple-elem {
	position: relative;
}
[ripple] {
	overflow: hidden;

	&.ripple-pointer {
		.ink {
			cursor: pointer;
		}
	}
}
.ink, .chkbox-ink {
	display: block; 
    position: absolute;
	background: rgba(0,0,0,0.125);
	border-radius: 100%;
	transform: scale(0);
    z-index: 1;

    &.ink-light {
        background: rgba(255,255,255,0.15);
    }
}
.chkbox-ink {
	top: 50%;
	left: 50%;
	z-index: 2;
	background: rgba(0,0,0,0.25);
}

.ink.animate {
    animation: ripple 0.45s linear;
}
.chkbox-ink.animate {
	animation: chkbox-ripple .8s cubic-bezier(0.23, 1, 0.32, 1);
}

.icon-lg {
	font-size: 50px;
	display: inline-block;
	transform: translateY(10px);
}

@keyframes ripple {
	100% {
        opacity: 0; 
        transform: scale(2.5);
    }
}

@keyframes chkbox-ripple {
	100% {
		opacity: 0;
		transform: scale(1);
	}
}