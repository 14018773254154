@import "../includes";

.page-selector-list-wrapper {
	min-height: 400px;
    max-height: 500px;
	overflow: hidden;
	overflow-y: auto;
	background-color: #dedede;
	box-shadow: inset 0 0 8px rgba(0,0,0,0.2);

	.page-list {
		display: flex;
		flex-flow: row wrap;
		justify-content: flex-start;
		align-items: flex-start;

		.page-item {
			flex: 0 0 50%;
			width: 50%;
			padding: 15px;
		}
	}

	.page-item {
		.inner-wrapper {
			border-radius: 2px;
			background-color: #fff;
			padding: 15px;
			box-shadow: bottom-shadow(1);
			transition: all $EASE-OUT-ANIM 450ms;
			overflow: hidden;

			&:hover {
				cursor: pointer;
				box-shadow: bottom-shadow(2);
			}

			&.selected {
				position: relative;
				background-color: #2979FF;
				color: #fff;

				&:after {
					@include material-icon-prop();
					content: "done";
					position: absolute;
					bottom: 3px;
					right: 3px;
					width: 24px;
					height: 24px;
					text-align: center;
					line-height: 24px;
					font-size: 20px;
					color: #fff;
					z-index: 3;
				}
			}
		}
	}
}

@media screen and (max-width: 1169px) {
	.page-selector-list-wrapper {
		-webkit-overflow-scrolling: touch;
	}
}

@media screen and (min-width: 768px) {
	.page-selector-list-wrapper .page-list {
		.page-item {
			flex: 0 0 33.333333%;
			width: 33.333333%;
		}
	}
}

@media screen and (min-width: 1024px) {
	.page-selector-list-wrapper .page-list {
		.page-item {
			flex: 0 0 25%;
			width: 25%;
		}
	}
}

@media screen and (min-width: 1170px) {
	.page-selector-list-wrapper .page-list {
		.page-item {
			flex: 0 0 20%;
			width: 20%;
		}
	}
}