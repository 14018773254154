@import "../includes";


.dataTables_wrapper {
	.row {
		flex: 0 0 100%;
		margin-left: 0;
		margin-right: 0;
		max-width: 100%;
	}
	.table-wrapper .inner-wrapper {
		overflow: hidden;
		overflow-x: auto;
	}
	.table {
		width: auto;
	}
}
.table-info-wrapper {
    @include flex-container-prop();

    .table-icon-wrapper {
        padding: 0;
        @include clearfix();
        margin-top: -5px;
        transform: translateY(5px);

        .table-item-icon {
            float: right;

            i {
                width: 100%;
                padding-left: 5px;
                cursor: default;
            }
        }
    }
}
.table-top-filter-bar {
    display: flex;
    justify-content: space-between;
}
.table-icon-wrapper {
	//padding: 0 10px;
	@include clearfix();
	margin-top: -5px;
    transform: translateY(3px);
}
.table-item-icon {
	@include clearfix();
	float: left;

	&.reset {
		display: inline-block;
		float: none;
		vertical-align: middle;
		margin-top: -3px;
	}

	i {
		display: block;
		float: left;
		width: 30px;
		height: 24px;
		text-align: center;
		line-height: 24px;
		cursor: pointer;
	}
}

.table-item-label-wrapper {
	.table-item-icon {
		display: none;

		+ .table-item-icon {
			margin-left: 0;
		}

		&:first-of-type {
			margin-left: 10px;
		}
	}

	&:hover {
		.table-item-icon {
			display: inline-block;
		}
	}
}

.color-box-value {
	display: inline-block;
	width: 20px;
	height: 20px;
	vertical-align: middle;
	border: solid 1px #333;
	margin-right: 3px;
}

$OP-ICON-COL-WIDTH: 150px;
$SITE-COL-WIDTH: 200px;
$TEMPLATE-COL-WIDTH: 240px;
.module-table {
	width: 100% !important;

	&[data-table-module="control"] {
		thead tr th {
			&:first-child {
				width: calc(100% - $OP-ICON-COL-WIDTH) !important;
			}
			&:last-child {
				width: 150px !important;
			}
		}	
	}	

	&[data-table-module="page"] {
		thead tr th {
			&:first-child {
				width: calc(100% - $TEMPLATE-COL-WIDTH - $SITE-COL-WIDTH - $OP-ICON-COL-WIDTH) !important;
			}
			&:nth-child(2) {
				width: $TEMPLATE-COL-WIDTH !important;
			}
			&:nth-child(3) {
				width: $SITE-COL-WIDTH !important;
			}
			&:last-child {
				width: $OP-ICON-COL-WIDTH !important;
			}
		}
	}

	&[data-table-module="template"],
	&[data-table-module="global"] {
		thead tr th {
			&:first-child {
				width: calc(100% - $SITE-COL-WIDTH - $OP-ICON-COL-WIDTH) !important;
			}
			&:nth-child(2) {
				width: $SITE-COL-WIDTH !important;
			}
			&:nth-child(3) {
				width: $OP-ICON-COL-WIDTH !important;
			}
		}
	}
	&[data-table-module="site"] {
		thead tr th {
			&:first-child {
				width: calc(100% - 200px - $OP-ICON-COL-WIDTH) !important;
			}
			&:nth-child(2) {
				width: 200px !important;
			}
			&:last-child {
				width: $OP-ICON-COL-WIDTH !important;
			}
		}

		&.main-item-list {
			thead tr th {
				&:first-child {
					width: calc(100% - 300px - $OP-ICON-COL-WIDTH) !important;
				}
				&:nth-child(2) {
					width: 300px !important;
				}
			}
		}
	}

	&[data-table-module="redirect_url"] {
		thead tr th {
			&:first-child {
				width: calc((100% - 140px - $OP-ICON-COL-WIDTH) / 2) !important;
			}
			&:nth-child(2) {
				width: calc((100% - 140px - $OP-ICON-COL-WIDTH) / 2) !important;
			}
			&:nth-child(3) {
				width: 140px !important;
			}
			&:last-child {
				width: $OP-ICON-COL-WIDTH !important;
			}
		}
	}

	&[data-table-module="user"] {
		thead tr th {
			&:first-child {
				width: calc((100% - 270px - $OP-ICON-COL-WIDTH) / 2) !important;
			}
			&:nth-child(2) {
				width: calc((100% - 270px - $OP-ICON-COL-WIDTH) / 2) !important;
			}
			&:nth-child(3) {
				width: 120px !important;
			}
			&:nth-child(4) {
				width: 150px !important;
			}
			&:last-child {
				width: $OP-ICON-COL-WIDTH !important;
			}
		}
	}
}
.property-table {
	width: 100% !important;

	thead tr th {
		&:first-child {
			width: 300px !important;
		}
		&:nth-child(2) {
			width: calc((100% - 450px - $OP-ICON-COL-WIDTH) / 2) !important;
		}
		&:nth-child(3) {
			width: 150px !important;
		}
		&:last-child {
			width: $OP-ICON-COL-WIDTH !important;
		}
	}
}

.row-flex{
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.submission-filter-bar {
    margin-right: auto;
    margin-left: 0;
    margin-bottom: 15px;

    .dataTables_length {
        display: flex;
		justify-content: center;
		align-items: center;

        .submission-type-select {
            width: auto;
            margin-left: 0.5em;
		}
		

    }
}

@media screen and (min-width: 64em) {
	
	.table-item-icon {
		& + .table-item-icon {
			margin-left: 5px;
		}
	}
}

@media screen and (min-width: 87.5em) {
	.dataTables_wrapper {
		max-width: 1200px;
		margin: 0 auto;
	}
}