@import "../includes";

$TAG-LINE-H: 30px;

.bootstrap-tagsinput {
	@include clearfix();
	width: 100%;
	box-shadow: none;
	border: none;
    border-bottom: solid 1px rgb(224, 224, 224);
    border-radius: 0;
	padding-left: 0;
    padding-right: 0;
	padding-bottom: 10px;
    resize: none;

    &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: rgb(224, 224, 224);
        font-weight: 400;
    }
    &::-moz-placeholder {
        /* Firefox 19+ */
        color: rgb(224, 224, 224);
        font-weight: 400;
    }
    &:-ms-input-placeholder {
        /* IE 10+ */
        color: rgb(224, 224, 224);
        font-weight: 400;
    }
    &:-moz-placeholder {
        /* Firefox 18- */
        color: rgb(224, 224, 224);
        font-weight: 400;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        box-shadow: 0 0 0px 1000px white inset;
    }
    &:not(:disabled):focus {
        border-bottom-color: #5cb3fd;
    }

	.tag {
		@include clearfix();
		background-color: #1E88E5;
		color: #fff;
		padding: 0 0 0 10px;
    	border-radius: 2px;
		float: left;
		line-height: $TAG-LINE-H;
		margin-bottom: 3px;
		font-size: 14px;

		[data-role="remove"] {
			width: 24px;
			height: $TAG-LINE-H;
			color: rgba(255,255,255,.6);
			float: right;
			margin-right: 4px;

			&:after {
				@include material-icon-prop();
				content: "close";
				height: $TAG-LINE-H;
				line-height: $TAG-LINE-H;
			}

			&:hover {
				box-shadow: none;
				color: rgba(255,255,255,1);
			}
		}

		& + .twitter-typeahead {
			.tt-input {
				margin-left: 5px;
			}
		}
	}

	input.tt-input {
		padding-left: 0;
		font-size: 14px;
		min-width: 160px;
	}
	input.tt-hint {
		color: #aaa;
	}
	
	.twitter-typeahead {
		display: inline-block !important;
		margin-top: 3px;

		.tt-menu {
			width: 150px;
		}
	}
}

// Custom tag (mixed with typeahead)
.tag-container {
	display: block;
	border: solid 1px #E0E0E0;
	padding: 5px 5px 0 5px;
	border-radius: 3px;
	overflow: hidden;
	
	ol {
		@include clearfix();

		li {
			@include clearfix();
			float: left;
			max-width: 100%;
			margin-right: 5px;
			margin-bottom: 5px;
		}
	}

	.tag {
		@include clearfix();
		float: left;
		display: flex;
		flex-flow: row nowrap;
		align-items: stretch;
		justify-content: flex-start;
		max-width: 100%;
		background-color: #1E88E5;
		overflow: hidden;
		border-radius: 2px;

		.tag-label {
			flex: 0 0 auto;
			color: #fff;
			line-height: $TAG-LINE-H;
			padding: 0 8px;
			font-size: 15px;
		}
		[role="remove"] {
			width: $TAG-LINE-H;
			height: $TAG-LINE-H;
			line-height: $TAG-LINE-H;
			color: rgba(255,255,255,.4);
			text-align: center;
			cursor: pointer;

			// &:before {
			// 	width: $TAG-LINE-H;
			// 	height: $TAG-LINE-H;
			// 	line-height: $TAG-LINE-H;
			// }

			&:hover {
				color: rgba(255,255,255,1);
			}
		}
	}
}