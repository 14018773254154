@import "../includes";

.control-list-container {
	.ctrl-value-list {
		.ctrl-value-item {
			display: flex;
			flex-flow: row nowrap;
			justify-content: flex-start;
			align-items: stretch;
			width: 100%;

			&.ui-sortable-helper {
				box-shadow: bottom-shadow(3);
				width: auto !important;
				max-width: 200px;
				background-color: #fff;
				border-radius: 4px;
				border-bottom-color: transparent;

				.order-no, .op-block {
					display: none;
				}
				.item-content {
					width: auto;
					max-width: 200px;
				}
				.item-image {
					max-width: 170px;

					img {
						max-width: 100%;
					}
				}
			}

			&.content-mode {
				.item-content {
					width: calc(100% - 110px);
				}
				.op-block {
					width: 50px;
					flex: 0 0 50px;
				}
			}
		}

		.page-item-placeholder {
			position: relative;
			height: 50px;
			transition: background-color 400ms $EASE-OUT;
			border: dashed 2px #FFB74D;
			background-color: #FFF3E0;

			&:before {
				content: "Drop here to reorder item";
				position: absolute;
				display: block;
				height: 50px;
				line-height: 48px;
				padding: 0 15px;
				color: #FFB74D;
				font-size: 18px;
			}
		}

		.order-no {
			width: 60px;
			flex: 0 0 60px;
			align-self: center;
			overflow: hidden;
			text-align: center;
			font-size: 22px;
			font-weight: 500;
			opacity: .4;
		}
		.item-content {
			// flex: 1 0 auto;
			align-self: center;
			// width: calc(100% - 210px);
			width: calc(100% - 260px);
			padding: 10px 15px;

            .item-label.using-global {
                display: flex;
                align-items: center;

                .using-global-icon {
                    display: flex;
                    cursor: default;
                    margin-right: 5px;

                    i {
                        color: #64B5F6;
                    }
                }
            }
          }
          .op-block {
			visibility: hidden;
			width: 150px;
			flex: 0 0 150px;
			align-self: center;
			overflow: hidden;
			text-align: center;
			@include clearfix();
			padding-top: 10px;
			padding-bottom: 10px;
		}
		.op-block-{
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 10px;
			.md-toggle-wrapper.inline-block{
				width: 100px;
				display: block;
				margin: 10px 0 0;
			}
            div:first-child {
              display: flex;
            }
		}

		.item-image {
			max-height: 100px;

			img {
				max-height: 100px;
			}
		}

		.op-btn {
			float: left;
			@include clearfix();

			i {
				float: left;
				width: 40px;
				height: 40px;
				line-height: 40px;
				text-align: center;
				opacity: .3;
				border-radius: 50%;

				
			}
			&:hover {
				i {
					opacity: 1;
					cursor: pointer;
				}
				&[aria-type="delete"] i {
					background-color: #EF5350;
					color: #fff;
				}
				&[aria-type="edit"] i {
					background-color: #448AFF;
					color: #fff;
				}
                &[aria-type="config"] i {
                    background-color: #64B5F6;
                    color: #fff;
                }
                &[aria-type="copy"] i {
                    background-color: #4caf50;
                    color: #fff;
                }
			}
		}
	}

	.no-data {
		font-size: 1.6rem;
		opacity: .5;
		margin: 0;
	}
}

.control-list-container {
	.ctrl-value-list {
		.ctrl-value-item {
			&:nth-child(even) {
				background-color: rgba(0, 0, 0, .05);
			}
			&:not(.ui-sortable-helper):hover {
				background-color: rgba(0, 0, 0, .07);

				.op-block {
					visibility: visible;
				}
			}
		}
	}
}