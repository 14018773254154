@import "../_includes";

.form-wrapper {
	position: relative;

	
	.form-busy-layer {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 10;
		display: none;

		&.working {
			display: block;
		}
	}
}
.form-group {
	margin-bottom: 30px;
	
	label + .md-toggle-wrapper {
		margin-top: 6px;
	}

	label.required {
		font-weight: 600;

		&:after {
			content: ' *'
		}
	}

	.md-toggle-wrapper {
		margin-bottom: 0;
	}

	&.no-mgn-bottom {
		margin-bottom: 0;
	}

	[type="radio"] + label[for] {
		cursor: pointer;
	}

	textarea.form-control {
		resize: none;
	}
}
.input-loader {
	text-align: center;
	vertical-align: bottom;
	line-height: 38px;

	.spinner {
		transform: translateY(7px);
	}
	.spinner svg {
		float: left;
	}
}

$ITEM-GRP-ICON-W: 37px;
.item-group {
	display: inline-block;
	width: auto;
	@include clearfix();

	.text-block {
		float: left;
		word-wrap: normal;
		line-height: $ITEM-GRP-ICON-W;
		padding-right: 15px;
	}
	.icon-block {
		float: left;
		@include clearfix();
		cursor: pointer;

		i {
			width: $ITEM-GRP-ICON-W;
			height: $ITEM-GRP-ICON-W;
			line-height: $ITEM-GRP-ICON-W;
			text-align: center;
			float: left;
		}

		&[role="delete"] {
			i {
				color: #EF9A9A;
			}
			&:hover {
				i {
					color: #C62828;
				}
			}
		}
	}
}
.button-panel {
	text-align: right;

	.btn + .btn {
		margin-left: 5px;
	}

	&.mgn-top-med {
		margin-top: 15px;
	}
}

.property-value-area {
	position: relative;
	display: block;
	width: 100%;
	height: 200px;
	border: solid 1px rgba(0,0,0,0.1);
	border-radius: 2px;

	.form-panel {
		position: relative;
		padding: 15px;
		z-index: 2;
		box-shadow: bottom-shadow(1);
	}

	.ddl-item-list-wrapper {
		position: absolute;
		top: 95px;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1;
		overflow: hidden;
		overflow-y: auto;
	}
}

.form-dummy {
	position: absolute;
	opacity: 0;
	width: 0;
	height: 0;
	z-index: 1000;
	left: -9999px;
	top: -9999px;
	overflow: hidden;
}
.btn-upload {

	input[type="file"] {
		width: 0.1px;
		height: 0.1px;
		opacity: 0;
		overflow: hidden;
		position: absolute;
		z-index: -1;
	}
}

.uppercase-text {
	text-transform: uppercase;
}